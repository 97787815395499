<div class="container-fluid">

  <div class="row bg-dark">
    <div class="container">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>

  <!--
  <div class="row" id="main-container" [style.height]="getOptimalMainContainerHeight()">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-2">

    </div>-->
  <!-- General Knowledge case study -->
  <div class="row bg-white" id="main-container">
    <div class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">
      <div class="row main-article">
        <div class="row fullWidth">
          <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting','Courier New', Courier, monospace;">General Knowldege Case Studies</h1>
        </div>

        <div class="row" id="main-container">
          <ng-template ngFor let-article [ngForOf]="getArticleGeneralKnowledge()">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="container-sm mt-3  remove-margin-padding round-top-borders" >
                <!-- Header section -->
                <div class="header bg-purple text-white p-2 remove-margin-padding  round-top-borders"
                [class.bg-purple]="article?.lectureType===LocalLectureType.Article" [class.bg-blue]="article?.lectureType===LocalLectureType.Video" >
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                </div>
                <div class="card bg-light remove-margin-padding">
                  <!--  <img class="card-img-top" src="{{article.images[0].location}}{{article.images[0].name}}" alt="Card image cap">-->
                  <img *ngIf="article?.images[0]?.location" class="card-img-top" src="{{article?.images[0]?.location}}" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Article)"  class="card-img-top" src="../../assets/AI.JPG" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Video)"  class="card-img-top" src="../../assets/VideoLecture_Icon.jpg" alt="article image">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-10">
                        <h2 class="card-title">{{article?.title}}</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <h3 class="card-title">Author:{{article?.user?.username}}</h3>
                      </div>
                    </div>
                    <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                    <div class="row">
                      <div class="col-md-6">
                        <a (click)="navigateToArticleDetail(article?.id)" class="btn btn-primary">More Details</a>
                      </div>
                    </div>
                  </div>

                </div>
               </div>

            </div>
          </ng-template>
        </div>
      </div>

      <!--
      <div class="form-inline float-left mr-1" *ngIf="getProductSize() > 0">
        <select class="from-control" [value]="articlesPerPage" (change)="changePageSize($event.target.value)">
          <option value="3">3 per Page</option>
          <option value="4">4 per Page</option>
          <option value="6">6 per Page</option>
        </select>
      </div> -->

      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCount" (click)="changePage(page)" class="btn btn-outline-primary"
          [class.active]="page == selectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="col-sm-2 col-md-3">
      <!-- <div class="card m-1 p-1 bg-light">
        <img class="card-img-top" src="../../assets/crypto_com.JPG" alt="Card image cap">
        <div class="card-body">
          <div class="row">
            <div class="col-md-10">
              <h5 class="card-title">Interested in staking crypto coin?</h5>
            </div>

          </div>
          <p class="card-text">Join crypto.com exchange and stake cro. Use referal code esqk2y962e to get bonus.
            Please invest responsibly</p>
          <div class="row">
            <div class="col-md-6"><a href="https://crypto.com/exch/esqk2y962e" target="_blank"
                class="btn btn-primary">More Detais</a> </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

<!-- Database -->
  <div class="row bg-grey" id="main-container">
    <div class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">
      <div class="row" id="main-article">
        <!-- Since title is only taking small part of the row, whenever second row has only one article that can
        fit the remaining available space of first row then content from second row is jumping up a bit. fullWidth is
        helping to mitigate the problem-->
        <div class="row fullWidth">
          <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting','Courier New', Courier, monospace;">Networking and Database Related Case Studies</h1>
        </div>

        <div class="row" id="main-container">
          <div *ngIf="getNetworkingDbRelatedArticles() == null || getNetworkingDbRelatedArticles().length == 0">
            <p>We don't have Security related case studies at this moment. Please come back later.</p>
          </div>
          <ng-template ngFor let-article [ngForOf]="getNetworkingDbRelatedArticles()">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="container-sm mt-3  remove-margin-padding round-top-borders" >
                <!-- Header section -->
                <div class="header bg-purple text-white p-2 remove-margin-padding  round-top-borders"
                [class.bg-purple]="article?.lectureType===LocalLectureType.Article" [class.bg-blue]="article?.lectureType===LocalLectureType.Video" >
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                </div>
                <div class="card bg-light remove-margin-padding">
                  <!--  <img class="card-img-top" src="{{article.images[0].location}}{{article.images[0].name}}" alt="Card image cap">-->
                  <img *ngIf="article?.images[0]?.location" class="card-img-top" src="{{article?.images[0]?.location}}" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Article)"  class="card-img-top" src="../../assets/AI.JPG" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Video)"  class="card-img-top" src="../../assets/VideoLecture_Icon.jpg" alt="article image">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-10">
                        <h2 class="card-title">{{article?.title}}</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <h3 class="card-title">Author:{{article?.user?.username}}</h3>
                      </div>
                    </div>
                    <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                    <div class="row">
                      <div class="col-md-6">
                        <a (click)="navigateToArticleDetail(article?.id)" class="btn btn-primary">More Details</a>
                      </div>
                    </div>
                  </div>

                </div>
               </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCount" (click)="changePage(page)" class="btn btn-outline-primary"
          [class.active]="page == intermediateSelectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>

    </div>
    <div class="col-sm-2 col-md-3">

    </div>
  </div>

   <!-- Performance And Security  -->
  <div class="row  bg-white" id="main-container">
    <div class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">

      <div class="row" id="main-container">
        <!-- Since title is only taking small part of the row, whenever second row has only one article that can
        fit the remaining available space of first row then content from second row is jumping up a bit. fullWidth is
        helping to mitigate the problem-->
        <div class="row fullWidth">
          <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting','Courier New', Courier, monospace;">Security and Performance Related Case Studies</h1>
        </div>
        <div class="row" id="main-container">
          <div *ngIf="getSecurityPerformanceRelatedArticles() == null || getSecurityPerformanceRelatedArticles().length == 0">
            <p>We don't have Performance related case studies at this moment. Please come back later.</p>
          </div>
          <ng-template ngFor let-article [ngForOf]="getSecurityPerformanceRelatedArticles()">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="container-sm mt-3  remove-margin-padding round-top-borders" >
                <!-- Header section -->
                <div class="header bg-purple text-white p-2 remove-margin-padding  round-top-borders"
                [class.bg-purple]="article?.lectureType===LocalLectureType.Article" [class.bg-blue]="article?.lectureType===LocalLectureType.Video" >
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                </div>
                <div class="card bg-light remove-margin-padding">
                  <!--  <img class="card-img-top" src="{{article.images[0].location}}{{article.images[0].name}}" alt="Card image cap">-->
                  <img *ngIf="article?.images[0]?.location" class="card-img-top" src="{{article?.images[0]?.location}}" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Article)"  class="card-img-top" src="../../assets/AI.JPG" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Video)"  class="card-img-top" src="../../assets/VideoLecture_Icon.jpg" alt="article image">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-10">
                        <h2 class="card-title">{{article?.title}}</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <h3 class="card-title">Author:{{article?.user?.username}}</h3>
                      </div>
                    </div>
                    <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                    <div class="row">
                      <div class="col-md-6">
                        <a (click)="navigateToArticleDetail(article?.id)" class="btn btn-primary">More Details</a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </ng-template>
        </div>
      </div>
      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCount" (click)="changePage(page)" class="btn btn-outline-primary"
          [class.active]="page == advancedSelectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>

    </div>
    <div class="col-sm-2 col-md-3">

    </div>
  </div>

  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

<app-nav-bar></app-nav-bar>

<div class="container-fluid">
  <!--
  <div class="row" id="main-container" [style.height]="getOptimalMainContainerHeight()">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-2">

    </div>-->
  <!-- This is for wider screen like laptop phone-->
  <div class="row" id="main-container">
    <div class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">
      <div class="row main-article">
        <div class="row">
          <div *ngIf="getArticles() == null || getArticles().length == 0">
            <h3>Sorry, couldn't find articles you are looking for. You can search by following Article type:</h3>
            <ul>
              <li>Java</li>
              <li>JavaScript</li>
              <li>ML</li>
              <li>HTML_CSS</li>
              <li>SecurityPerformance</li>
              <li>NetworkingDb</li>
              <li>GI</li>
            </ul>
            <h3>Alternatively, you can also search by Article title (without quotes) such as "Introduction To Java"</h3>
          </div>
        </div>
        <div class="row fullWidth">
          <div *ngIf="getArticles() != null || getArticles().length > 0">
            <h3>Showing Search results for search word: <b>{{searchString}}</b></h3>
          </div>
        </div>
        <div class="row">
          <ng-template ngFor let-article [ngForOf]="getArticles()">
            <div class="col-6 col-md-4 p-2">
              <div class="card m-1 p-1 bg-light">
                <!--  <img class="card-img-top" src="{{article.images[0].location}}{{article.images[0].name}}" alt="Card image cap">-->
                <img class="card-img-top" src="{{article?.images[0]?.location}}" alt="Card image cap" />
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-10">
                      <h5 class="card-title">{{article?.title}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-10">
                      <h5 class="card-title">Author:{{article?.user?.username}}</h5>
                    </div>
                  </div>
                  <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                  <div class="row">
                    <div class="col-md-6">
                      <a (click)="navigateToArticleDetail(article?.id)" class="btn btn-primary">More Details</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCount" (click)="changePage(page)" class="btn btn-outline-primary"
          [class.active]="page == selectedPage">
          {{page}}
        </button>
      </div>
    </div>
    <div class="col-sm-2 col-md-3">
      <div class="card m-1 p-1 bg-light">
        <img class="card-img-top" src="../../assets/crypto_com.JPG" alt="Card image cap">
        <div class="card-body">
          <div class="row">
            <div class="col-md-10">
              <h5 class="card-title">Interested in staking crypto coin?</h5>
            </div>

          </div>
          <p class="card-text">Join crypto.com exchange and stake cro. Use referal code esqk2y962e to get bonus.
            Please invest responsibly</p>
          <div class="row">
            <div class="col-md-6"><a href="https://crypto.com/exch/esqk2y962e" target="_blank"
                class="btn btn-primary">More Detais</a> </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

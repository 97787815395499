<div class="container-fluid">

  <div class="row bg-dark">
    <div class="container">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">
      <div class="bg-danger mt-2 p-2 text-center text-white" *ngIf="errorMessage != null">
        Error: {{errorMessage}}
      </div>
    </div>
    <div class="col-sm-2"></div>
  </div>






  <div class="row">
    <div class="col-sm-3"></div>

    <div class="col-sm-6">
      <div class="container-sm mt-3  border remove-margin-padding round-top-borders">
         <!-- Header section -->
         <div class="header bg-purple text-white p-2  remove-margin-padding round-top-borders">
          <h2 class="text-center m-0">Sign up for free</h2>
          <p style="text-align: center; font-style: italic;">Credit card (<i class="fa fa-credit-card"></i>) is not required for Sign up</p>
        </div>
        <div class="bg-success text-white p-2  remove-margin-padding">
          <form novalidate #form="ngForm" (ngSubmit)="authenticate(form)">

            <div class="form-row">
              <div class="form-group col-sm-1"></div>
              <div class="form-group col-sm-5">
                <label for="firstname">First Name</label>
                <input class="form-control" id="firstname" name="firstname" [(ngModel)]="user.firstname" minlength="2"
                  required maxlength="15" #firstname="ngModel" />
                <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="alert">
                  <div> First name is required and need to be between 2 to 15 characters. </div>
                </div>
              </div>

              <div class="form-group col-sm-5">
                <label for="email">Email</label>
                <input email class="form-control" id="email" name="email" [(ngModel)]="user.email" required
                  #email="ngModel" [ngModelOptions]="{ updateOn: 'blur' }" appUniqueUseremail
                  [configValue]="'userEmailCheck'" />
                <div *ngIf="(!email.errors?.emailTaken) && email.invalid && (email.dirty || email.touched)" class="alert">
                  <div> Please enter valid email. </div>
                </div>
                <div *ngIf="email.errors?.emailTaken && (email.dirty || email.touched)" class="alert">Email is already
                  taken</div>
              </div>
              <div class="form-group col-sm-1"></div>
            </div>



            <div class="form-row">
              <div class="form-group col-sm-1"></div>
              <div class="form-group col-sm-5">
                <label for="username">User Name (5 to 10 characters without space)</label>
                <input type="text" class="form-control" id="username" name="username" [(ngModel)]="user.username"
                  minlength="5" maxlength="10" required #username="ngModel" [ngModelOptions]="{ updateOn: 'blur' }"
                  appUniqueUsername />
                <!-- <app-spinner *ngIf="username.pending"></app-spinner> -->
                <div *ngIf="(!username.errors?.usernameTaken) && username.invalid && (username.dirty || username.touched)"
                  class="alert">
                  <div> User name is required and need to be between 5 to 10 characters. </div>
                </div>
                <div *ngIf="username.errors?.usernameTaken && (username.dirty || username.touched)" class="alert">Username
                  is already taken</div>
              </div>

              <div class="form-group col-sm-5">
                <label for="country">Country</label>
                <input class="form-control" id="country" name="country" [(ngModel)]="user.country" required minlength="3"
                  maxlength="35" #country="ngModel" />
                <div *ngIf="country.invalid && (country.dirty || country.touched)" class="alert">
                  <div> Country is required and need to be between 3 to 35 characters. </div>
                </div>
              </div>
              <div class="form-group col-sm-1"></div>
            </div>

            <!--TODO: Password Re-enter field-->
            <div class="form-row">
              <div class="form-group col-sm-1">
              </div>
              <div class="form-group col-sm-5">
                <label for="password">Password (7 to 8 characters without space)</label>
                <input type="password" class="form-control" id="password" name="password" [(ngModel)]="user.password"
                  minlength="7" maxlength="8" required #password="ngModel" />
                <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert">
                  <div> Password is required and need to be between 7 to 8 characters. </div>
                </div>
              </div>
              <div class="form-group col-sm-1"></div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-1"></div>
              <div class="form-goup col-sm-4">
                <div class="form-check">
                  <input type="checkbox" id="ageCheck" name="ageCheck" [(ngModel)]="user.above18" />
                  <label for="ageCheck"> Please tick the check box if you are above 18 years of age</label>
                </div>
              </div>
              <div class="form-goup col-sm-6">
                <div class="form-check">
                  <input type="checkbox" id="subscriptionCheck" name="subscriptionCheck"
                    [(ngModel)]="user.emailSubscription" />
                  <label for="subscriptionCheck"> Please untick the check box if you don't want to subscribe our
                    newsletter for the latest updates, articles and insights.</label>
                </div>
              </div>
            </div>

            <div class="form-row">&nbsp;</div>
            <div class="form-row">&nbsp;</div>
            <div class="form-row">&nbsp;</div>

            <div class="text-center">
              <button class="btn btn-primary m-1" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>

    </div>

    <div class="col-sm-3"></div>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
      <p style="font-style: italic;">* Please note that by providing your information and registering with us, you
        agreed to our
        <a href="#" (click)="opensTermsDialog($event)">privacy policy and Website/Application terms of use.</a>
      </p>
    </div>
    <div class="col-md-2"></div>
  </div>


  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

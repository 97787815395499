import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {Product} from "./product.model";
import {Question} from "./question.model";
import {Subject} from "./subject.model";
import {Cart} from "./cart.model";
import {Article} from "./article.model";
import {QuestionOption} from "./questionOption.model";
import {Message} from "./message.model";
import { Prediction } from "./prediction.model";
import {catchError, map } from "rxjs/operators"
import { HttpHeaders } from "@angular/common/http";
import {Socket} from "ngx-socket-io";
import { User } from 'src/app/model/user.model';
import { Subscription } from 'src/app/model/subscription.model';
import { Reply } from 'src/app/model/reply.model';
import { Comment } from 'src/app/model/comment.model';
import { UserPrivacySetting } from "./userPrivacySetting.model";
import { Behaviour } from "./Behaviour.model";
import { Image } from 'src/app/model/image.model';
import { ArticleImage } from 'src/app/model/articleImage.model';
import { ApiResponse } from "./apiResponse.model";
import {RedirectApiResponse} from "src/app/model/redirectapiResponse.model";
import { environment } from "src/environments/environment.docker";
import { UserSubject } from "./userSubject.model";
import {CompletedQuestion} from "./completedQuestion.model";
import { GraphData } from "./graph.model";
import { Feedback } from "./feedback.model";

const PROTOCOL = environment.urlProtocol;
const PORT = environment.urlPort;
const MLPORT = 5000;


@Injectable({
  providedIn: 'root'
})
export class RestDataSource{
  baseUrl: string;
  auth_token: string;

  mlPredictionUrl: string;

  //constructor(private http: HttpClient, private socket: Socket) {
  constructor(private http: HttpClient) {
     this.baseUrl = `${PROTOCOL}://${location.hostname}:${PORT}/api/`;
   //this.baseUrl = `${PROTOCOL}://${location.hostname}:${PORT}/`;
    //  this.mlPredictionUrl = `${PROTOCOL}://${location.hostname}:${MLPORT}/`;
  }

  //SocketIO
 // updatedJob = this.socket.fromEvent<Order>('updatedJob');

  getProducts():Observable<Product[]> {
    return this.sendRequest<Product[]>("GET",`${this.baseUrl}product-listing-service/products`);
  }


  getLearningPathGraph(name: string): Observable<GraphData> {
    return this.sendRequest<GraphData>("GET",`${this.baseUrl}product-listing-service/learningpath/${name}`);
  }

  getSubjectsByLevelCategoryAndSubCategory(level: number, category: string, subcategory: string):Observable<Subject[]> {
    return this.sendRequest<Subject[]>("GET",`${this.baseUrl}product-listing-service/subjects/search/${level}/${category}/${subcategory}`);
  }

  getSubjectsByLevelAndSubCategory(level: number, subCategory: string):Observable<Subject[]> {
    return this.sendRequest<Subject[]>("GET",`${this.baseUrl}product-listing-service/subjects/search/${level}/${subCategory}`);
  }

  getSubjectsByAuthorId(id: number):Observable<Subject[]> {
    return this.sendRequest<Subject[]>("GET",`${this.baseUrl}product-listing-service/subjects/authors/${id}`);
  }


   //update subject
   updateSubject(subject: Subject):Observable<Subject> {
    return this.sendRequest<Subject>("PUT",`${this.baseUrl}product-listing-service/subjects/`, null, null, null, null, null,  null, null, null, null, subject);
   }

   //create question
   createQuestion(question: Question):Observable<Question> {
    return this.sendRequest<Question>("POST",`${this.baseUrl}product-listing-service/questions/`, null, null, null, null, null,  null, null, null, null, null, question);
   }


   updateQuestion(question: Question):Observable<Question> {
    return this.sendRequest<Question>("PUT",`${this.baseUrl}product-listing-service/questions/`, null, null, null, null, null,  null, null, null, null, null, question);
   }

   saveSubjectAndDes(subject: Subject):Observable<Subject> {
    return this.sendRequest<Subject>("POST",`${this.baseUrl}product-listing-service/subjects/`, null, null, null, null, null,  null, null, null, null, subject);
   }

  getQuestionsBySubjectId(subjectId: number):Observable<Question[]> {
    return this.sendRequest<Question[]>("GET",`${this.baseUrl}product-listing-service/questions/search/${subjectId}`);
  }

  getWeeklyQuiz():Observable<Subject[]> {
    return this.sendRequest<Subject[]>("GET",`${this.baseUrl}product-listing-service/questions/quiz`);
  }

  getUserSubjectsByUserId(userId: number):Observable<UserSubject[]> {
    return this.sendRequest<UserSubject[]>("GET",`${this.baseUrl}product-listing-service/userSubject/${userId}`);
  }

  getUserSubjectsByUserAbdSubjectId(userId: number, subjectId: number):Observable<UserSubject> {
    return this.sendRequest<UserSubject>("GET",`${this.baseUrl}product-listing-service/userSubject/${userId}/${subjectId}`);
  }

  saveUserSubject(userSubject: UserSubject): Observable<UserSubject> {
    return this.sendRequest<UserSubject>("POST",`${this.baseUrl}product-listing-service/userSubject/`, null, null, null, null, null,  null, null, userSubject);
  }

  updateUserSubject(userSubject: UserSubject): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("PUT",`${this.baseUrl}product-listing-service/userSubject/`, null, null, null, null, null,  null, null, userSubject);
  }

  saveSubjectPracticeProgress(completedQuestions: CompletedQuestion[]): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("POST",`${this.baseUrl}product-listing-service/userSubject/progress/`, null, null, null, null, null,  null, null, null, completedQuestions);
  }


  compileAndRunCode(submission: QuestionOption): Observable<ApiResponse<null>> {
   //return this.sendRequest<ApiResponse<null>>("POST",`http://localhost:8500/practice-coding/submit-code`, null, null, null, null, null,  null, null, null, null, null, null, null, null, submission);
   return this.sendRequest<ApiResponse<null>>("POST",`${this.baseUrl}practice-coding/submit-code`, null, null, null, null, null,  null, null, null, null, null, null, null, null, submission);
  }


  subscribe(priceId: string, username: string, keycloakUserId: string): Observable<RedirectApiResponse> {
    return this.sendRequest<RedirectApiResponse>("POST",`${this.baseUrl}user-service/subscription/create-checkout-session/${priceId}/${username}/${keycloakUserId}`);
  }


  createFeedback(feedback: Feedback): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("POST",`${this.baseUrl}user-service/customers/feedback/`, null, null, null, null, null,  null, null, null, null, null, null, null, feedback);
  }

  manageBilling(username: string): Observable<RedirectApiResponse> {
    return this.sendRequest<RedirectApiResponse>("POST",`${this.baseUrl}user-service/subscription/manage-billing-session/${username}`);
  }

  authenticate(user: string, pass: string): Observable<boolean> {
    return this.http.post<any>(this.baseUrl + "login", {
      name: user, password: pass
    }).pipe(map(response => {
        this.auth_token = response.success ? response.token : null;
        return response.success;
    }));
  }

  //this is for communicating with spring backend (currently authenticate() above is for node backend)
  authenticateUser(user: User){

    window.sessionStorage.setItem("userdetails",JSON.stringify(user));
    return this.http.get(this.baseUrl + "user",{ observe: 'response',withCredentials: true });
  }

  saveProduct(product: Product): Observable<Product> {
    return this.sendRequest<Product>("POST",`${this.baseUrl}product-listing-service/products/`,null, product);
  }

  saveImage(formData: FormData): Observable<Image[]> {
   return this.sendRequest<Image[]>("POST", `${this.baseUrl}product-listing-service/products/images`,null, null, null, null,  formData);
  }

  deleteImage(id: number): Observable<Object> {
    return this.sendRequest<Object>("DELETE", `${this.baseUrl}product-listing-service/products/images/${id}`);
   }

  updateProduct(product): Observable<Product> {
    return this.sendRequest<Product>("PUT",`${this.baseUrl}product-listing-service/products/${product.id}`, null, product);
  }

  deleteProduct(id: number): Observable<Product> {
    return this.http.delete<Product>(`${this.baseUrl}product/${id}`, this.getOptions());
  }

  getUserByName(username: string): Observable<User> {
   // return this.http.get<User>(this.baseUrl + "user-service/customers/"+username, {withCredentials: true});
    return this.sendRequest<User>("GET",`${this.baseUrl}user-service/customers/${username}`);
  }

  getUserProfileSettingByName(username: string): Observable<User> {
     return this.sendRequest<User>("GET",`${this.baseUrl}user-service/customers/userprofilesetting/${username}`);
   }

  getUserProfile(username: string): Observable<User> {
    return this.sendRequest<User>("GET",`${this.baseUrl}user-service/public/userprofile/${username}`);
  }

  isUserNameUnique(username: string): Observable<boolean> {
    return this.sendRequest<boolean>("GET",`${this.baseUrl}user-service/customers/exist/username/${username}`);
  }

  isUserEmailUnique(email: string): Observable<boolean> {
    return this.sendRequest<boolean>("GET",`${this.baseUrl}user-service/customers/exist/email/${email}`);
  }

  isSubscriptionEmailUnique(email: string): Observable<boolean> {
    return this.sendRequest<boolean>("GET",`${this.baseUrl}user-service/subscription/email/${email}`);
  }

  saveUser(user: User): Observable<User> {
    return this.sendRequest<User>("POST",`${this.baseUrl}user-service/customers/`, user);
  }

  saveUserProfileSetting(privacySettings: UserPrivacySetting[]): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("PUT",`${this.baseUrl}user-service/customers/userprofilesetting`, null, null, null, null, null,  null, null, null, null, null, null, null, null, null, privacySettings);
  }

  trackUserBehaviour(behaviour: Behaviour): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("POST",`${this.baseUrl}user-service/customers/behaviour`, null, null, null, null, null,  null, null, null, null, null, null, null, null, null, null, behaviour);
  }

  confirmUserEmailCode(user: User): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("POST",`${this.baseUrl}user-service/customers/emailconfirmation`, user);
  }

  updateUser(user: User): Observable<User> {
    return this.sendRequest<User>("PUT",`${this.baseUrl}user-service/customers/${user.id}`, user);
  }

  saveSubscription(subscription: Subscription): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("POST",`${this.baseUrl}user-service/subscription/`, null, null, null, null, null,  null, subscription);
  }

  saveSubscriptionEmailCode(subscription: Subscription): Observable<ApiResponse<null>> {
    return this.sendRequest<ApiResponse<null>>("POST",`${this.baseUrl}user-service/subscription/emailconfirmation`, null, null, null, null, null,  null, subscription);
  }


  saveArticle(article: Article): Observable<Article> {
    //return this.http.post<User>(this.baseUrl + "user-service/customers/", user);
    return this.sendRequest<Article>("POST",`${this.baseUrl}product-listing-service/articles/`, null, null, null, null, null,  article);
  }

  updateArticle(article: Article): Observable<Article> {
    return this.sendRequest<Article>("PUT",`${this.baseUrl}product-listing-service/articles/${article.id}`, null, null, null, null, null,  article);
  }

  saveArticleImage(formData: FormData): Observable<ArticleImage[]> {
    return this.sendRequest<ArticleImage[]>("POST", `${this.baseUrl}product-listing-service/articles/images`,null, null, null, null,  formData);
   }

   deleteArticleImage(id: number): Observable<Object> {
     return this.sendRequest<Object>("DELETE", `${this.baseUrl}product-listing-service/articles/images/${id}`);
    }

  getArticles(excludeCaseStudyArticles: boolean, excludeLearningArticles: boolean): Observable<Article[]> {
    return this.sendRequest<Article[]>("GET",`${this.baseUrl}product-listing-service/articles/${excludeCaseStudyArticles}/${excludeLearningArticles}`);
  }

  getAllArticlesByAuthorId(id): Observable<Article[]> {
    return this.sendRequest<Article[]>("GET",`${this.baseUrl}product-listing-service/articles/authors/${id}`);
  }

  getArticlesIdAndTitleByTitle(title: string): Observable<Article[]> {
    return this.sendRequest<Article[]>("GET",`${this.baseUrl}product-listing-service/articles/titles/${title}`);
  }

  getArticleDetailById(articleId: number): Observable<Article> {
    //console.log("rest datasource > getArticleDetailById"+articleId);
    return this.sendRequest<Article>("GET",`${this.baseUrl}product-listing-service/articles/${articleId}`);
  }

  getRelatedArticlesById(articleId: number): Observable<Article[]> {
    return this.sendRequest<Article[]>("GET",`${this.baseUrl}product-listing-service/articles/${articleId}/related`);
  }


  getArticleBySubcategory(subcategory: string): Observable<Article[]> {
    return this.sendRequest<Article[]>("GET",`${this.baseUrl}product-listing-service/articles/subcategory/${subcategory}`);
  }

  getArticleByCategory(category: string): Observable<Article[]> {
    return this.sendRequest<Article[]>("GET",`${this.baseUrl}product-listing-service/articles/category/${category}`);
  }

  searchArticle(searchCategory: string): Observable<Article[]> {
    return this.sendRequest<Article[]>("GET",`${this.baseUrl}product-listing-service/articles/search/${searchCategory}`);
  }

  getRepliesByArticleAndCommentId(articleId: number, commentId: number): Observable<Reply[]> {
    return this.sendRequest<Reply[]>("GET", `${this.baseUrl}product-listing-service/articles/${articleId}/comments/${commentId}/replies`);
  }

  //Need to map Model object (Currenlty using Comment object) location in sendRequest method as well
  askQuestion(message: Message, username: String): Observable<Message> {
    return this.sendRequest<Message>("POST",`${this.baseUrl}user-service/ask-assistant/${username}`, null, null, null, null, null,  null, null, null, null, null, null, message);
  }

  askSummary(articleId: number, username: String): Observable<Message> {
    return this.sendRequest<Message>("GET",`${this.baseUrl}user-service/ask-assistant/articlesummary/${articleId}/${username}`);
  }

  askCodingHint(message: Message, questionId: number, username: String): Observable<Message> {
    console.log("Asking coding hint"+questionId, username);
    return this.sendRequest<Message>("POST",`${this.baseUrl}user-service/ask-assistant/codinghint/${questionId}/${username}`, null, null, null, null, null,  null, null, null, null, null, null, message);
  }

  saveComment(comment: Comment): Observable<Comment> {
    //return this.http.post<User>(this.baseUrl + "user-service/customers/", user);
    return this.sendRequest<Comment>("POST",`${this.baseUrl}product-listing-service/comments`, null, null, comment, null);
  }

  updateComment(comment: Comment): Observable<Comment> {
    //  return this.http.put<User>(`${this.baseUrl}user-service/customers/${user.id}`, user, {withCredentials: true});

      return this.sendRequest<Comment>("PUT",`${this.baseUrl}product-listing-service/comments/${comment.id}`, null, null, comment, null);
    }

  saveReply(reply: Reply): Observable<Reply> {
    return this.sendRequest<Reply>("POST",`${this.baseUrl}product-listing-service/articles/comments/replies`,null, null, null, reply, null);
  }

  updateReply(reply: Reply): Observable<Reply> {
    return this.sendRequest<Reply>("PUT",`${this.baseUrl}product-listing-service/articles/comments/replies/${reply.id}`,null, null, null, reply, null);
  }

  private sendRequest<T>(verb: string, url: string, userBody?: User, productBody?: Product, commentBody?: Comment, replyBody?: Reply, formBody?: FormData, articleBody?: Article, subscriptionBody?: Subscription,  userSubject?: UserSubject,  completedQuestions?: CompletedQuestion[], subject?: Subject, question?: Question, message?: Message, feedback?: Feedback, codeSubmission?: QuestionOption, privacySettings?: UserPrivacySetting[], behaviour?: Behaviour ): Observable<T> {

    let body = userBody ? userBody : productBody ? productBody : commentBody ? commentBody : replyBody ? replyBody: formBody ? formBody: articleBody ? articleBody: subscriptionBody ? subscriptionBody : userSubject ? userSubject :  completedQuestions ? completedQuestions : subject ? subject : question ? question : message ? message : feedback ? feedback : codeSubmission ? codeSubmission : privacySettings ? privacySettings : behaviour ? behaviour : null;
    return this.http.request<T>(verb, url, {body: body, withCredentials: true}, ).pipe(catchError(
     //  (error: Response) =>{ console.log(error);return throwError(`Network Error: ${error.statusText} (${error.status})`);}
      (error: Response) =>{ return throwError(error);}
    ));
  }


  private getOptions() {
    return {
      headers: new HttpHeaders ({
        "Authorization": `Bearer<${this.auth_token}>`
      })
    }
  }

  getPrediction():Observable<Prediction> {
    console.log(this.mlPredictionUrl+"baseURL");
    return this.http.get<Prediction>(this.mlPredictionUrl + "weatherpredict");
  }





}

<app-nav-bar></app-nav-bar>

<div class="container-fluid">

  <div id="main-container">
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="form-group col-sm-10 text-center">
        <h2>Our <i class="fa fa-lock"></i> Secure Payment System is Provided By Stripe</h2>
      </div>
      <div class="form-group col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-sm-6 col-md-4">
        <h3><b>Step 1:</b> Please choose subscription option that best suits you </h3>
      </div>
      <div class="col-sm-6 col-md-4">
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-sm-6 col-md-4 main-content">
        <div class="container-sm mt-3 remove-margin-padding round-top-borders">
          <!-- Header section -->
          <div class="header bg-purple text-white p-2  remove-margin-padding round-top-borders">
            <h2 class="text-center m-0">&nbsp;</h2>
          </div>
          <div class="card remove-margin-padding " [class.bg-success]="!isMonthlyPlanSelected" [class.bg-blue]="isMonthlyPlanSelected" [class.box-shadow]="isMonthlyPlanSelected">
            <form novalidate #form="ngForm" (click)="formClicked($event, monthlypriceId)">

              <div class="form-row">
                <div class="form-group col-sm-1"></div>
                <div class="form-group col-sm-10 text-center">

                  <h2>Only $4 NZD a month</h2>

                </div>
                <div class="form-group col-sm-1"></div>
              </div>
              <div class="form-row">

                <div class="form-group col-sm-12">
                  <ul>
                    <li> Give you access to all Premium and non Premium articles <i class="fa fa-check"></i></li>
                    <br />
                    <li> Can Practice all Premium and non Premium exercises <i
                        class="fa fa-check"></i></li>
                    <br />
                    <li> Allows up to 300 AI Assistant queries per month, including article summaries <i
                      class="fa fa-check"></i></li>
                    <br />
                    <li> Cancel anytime!</li>

                    <!-- Following li is added just to have same length as next plan -->
                    <li id="linoDecor">&nbsp; </li>
                  </ul>
                </div>

              </div>
            </form>
          </div>
         </div>

      </div>

      <div class="col-sm-6 col-md-4 main-content">
        <div class="container-sm mt-3  border remove-margin-padding round-top-borders">
           <!-- Header section -->
          <div class="header bg-purple text-white p-2 remove-margin-padding round-top-borders">
           <h2 class="text-center m-0">Our most popular plan</h2>
          </div>
          <div class="card bg-success text-white remove-margin-padding" [class.bg-success]="!isThreeMonthlyPlanSelected" [class.bg-blue]="isThreeMonthlyPlanSelected" [class.box-shadow]="isThreeMonthlyPlanSelected">
            <form novalidate #form="ngForm" (click)="formClicked($event, threemonthpriceId)">
              <div class="form-row">
                <div class="form-group col-sm-1"></div>
                <div class="form-group col-sm-10 text-center">
                  <h2>Only $10 NZD for three months</h2>
                  <s>$12 NZD</s>
                </div>
                <div class="form-group col-sm-1"></div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <ul>
                    <li> Give you access to all Premium and non Premium articles <i class="fa fa-check"></i></li>
                    <br />
                    <li> Can Practice all Premium and non Premium exercises <i
                        class="fa fa-check"></i></li>
                    <br />
                    <li> Allows up to 300 AI Assistant queries per month, including article summaries <i
                      class="fa fa-check"></i></li>
                    <br />
                    <li> Cancel anytime!</li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>

      <div class="col-md-2"></div>

    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="form-group col-sm-10 text-center">
        <p> We <b>don't</b> store any of your credit card information in our
          hightechdistrict.com Servers.</p>
      </div>
      <div class="form-group col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-sm-6 col-md-6">
        <h3><b>Step 2:</b> Please <a routerLink="/myaccount">login</a> to your account or <a routerLink="/registration"> register </a> if you don't have one. </h3>
      </div>
      <div class="col-sm-6 col-md-2">
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

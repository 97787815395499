import { Component, OnInit, OnDestroy } from '@angular/core';
import cytoscape from 'cytoscape';
import { Router } from '@angular/router';
import { ComponentLiteralNavName } from '../../service/constants';
import { GraphRepository } from '../../model/graph.repository';

@Component({
  selector: 'app-learning-path',
  templateUrl: './learning-path.component.html',
  styleUrls: ['./learning-path.component.css'],
})
export class LearningPathComponent implements OnInit, OnDestroy {
  private cy: any;

  constructor(
    private router: Router,
    private graphRepository: GraphRepository
  ) {}


  ngOnInit() {
    this.getLearningPathByName("WebDev1");
  }

  ngOnDestroy() {
    if (this.cy) {
      this.cy.destroy();
    }
  }

  //   Sample Required data format for elements
  //   elements: {
  //     nodes: [
  //       {
  //         data: { id: 1, name: 'Java1', rank: 1 },
  //       },

  //       {
  //         data: { id: 2, name: 'WebDev1', rank: 3 },
  //       },

  //     ],
  //     edges: [
  //       {
  //         data: { id: 'J12', source: 1, target: 5 },
  //       },

  //       {
  //         data: { id: 'J2We', source: 5, target: 2 },
  //       },

  //     ],
  //   },
  getLearningPathByName(name: string) {
    this.graphRepository.getLearningPathGraph(name).subscribe((data) => {

      if (data.nodes != null) {
        const transformedData = {
          nodes: data.nodes.map((node) => ({ data: node })),
          edges: data.edges.map((edge) => ({ data: edge })),
        };
        console.log('data' + JSON.stringify(transformedData));
        this.cy = cytoscape({
          container: document.getElementById('cy'), // container to render the graph

          elements: transformedData,

          layout: {
            name: 'breadthfirst',
            avoidOverlap: true,
            directed: false,
            // sort: function(a, b) {
            //   // Custom sorting function
            //   return a.data('rank') - b.data('rank'); // Sort nodes based on datarank
            // }
          },

          userZoomingEnabled: false,

          // so we can see the ids
          style: [
            {
              selector: 'node',
              style: {
                'background-color': 'green',
                label: 'data(name)',
                shape: 'round-rectangle',
                padding: '15%',
              },
            },
            {
              selector: 'edge',
              style: {
                width: 3,
                'line-color': 'blue',
                'target-arrow-color': 'blue',
                'target-arrow-shape': 'triangle',
                'curve-style': 'unbundled-bezier',
              },
            },
          ],
        });

        /**
         * Just for reference. Can't use function like following but arrow function to get component this.
         * Alternatively can append .bind(this); to following function
         *  this.cy.on('tap', 'node', function(evt){
         * var node = evt.target;
         * console.log( 'tapped ' + node.id() );
         * });
         */
        this.cy.on('tap', 'node', (evt) => {
          var node = evt.target;
          this.router.navigate([
            ComponentLiteralNavName.CompArticleDetail,
            node.id(),
          ]);
        });

        /**
         * Set the cursor to pointer when mouse enters a node.
         * This approach is used as setting through css style in above code block is not working
         */
        this.cy.on('mouseover', 'node', (event) => {
          this.cy.container().style.cursor = 'pointer';
        });

        // Reset the cursor when mouse leaves a node
        this.cy.on('mouseout', 'node', (event) => {
          this.cy.container().style.cursor = '';
        });

        this.updateMessage(data.nodes);
      } else {
        this.cy.destroy();

        this.updateMessage(null);
      }

    });
  }

   updateMessage(data: any) {
    let message = document.getElementById('no-elements-message');
    if (data == null) {
      message.style.display = 'block';
    } else {
      message.style.display = 'none';
    }
  }
}

<app-nav-bar></app-nav-bar>

<div class="container-fluid">
  <div class="container-fluid bg-white">

    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8"></div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 col-sm-12">




          <div id="no-elements-message" style="display: none; text-align: center; color: orange">
            <p>Sorry currently learning path for choosen course is under development. Please check again later.</p>
            <p>Alternatively please contact us through email. Thanks.</p>
          </div>

          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <label for="skillSet"><b>Choose Learning Path: </b></label>
              <select class="from-control"  id="skillSet" (change)="getLearningPathByName($event.target.value)">
                <option value="WebDev1"> Web Application Development Series 1</option>
                <option value="WebDev2"> Web Application Development Series 2</option>
                <option value="MachLearn1"> Machine Learning Series 1</option>
                <option value="Networking1"> Networking Series 1</option>
              </select>
            </div>

          </div>
          <br/>
          <div id="cy" style="width: 100%; height: 600px;"></div>
          <p><b>Note that these learning paths are suggestion only. You can choose from any point that is suitable for you.</b></p>
          <ul>
            <li class="description">For better visibility you can click and drag green nodes <span class="rectangle"></span></li>
            <li> You can also click on any nodes to navigate to relevant lecture page</li>

          </ul>



      </div>
      <div class="col-md-2">



      </div>
    </div>

  </div>
  <br/><br/>

  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

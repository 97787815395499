import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Message } from '../../model/message.model';
import { OpenAiRepository } from '../../model/openai.repository';
import * as _ from 'lodash';
import { UserRepository } from '../../model/user.repository';

@Component({
  selector: 'app-aichat',
  templateUrl: './aichat.component.html',
  styleUrls: ['./aichat.component.css'],
})
export class AichatComponent implements OnInit {
  public messages: Message[] = [];
  public showSpinner = false;
  public errorMessage: string;

  @ViewChild('chat_message')
  chat_message: ElementRef;

  constructor(
    private openAiRepository: OpenAiRepository,
    private userRepository: UserRepository
  ) {}

  ngOnInit(): void {}

  getMessages(): Message[] {
    return this.messages;
  }

  scrollToBottom() {
    let chatWindow = document.getElementById("main__chat_window");
    console.log("Scroll Height"+ chatWindow.scrollHeight);
    chatWindow.scrollTop = chatWindow.scrollHeight;
  }

  sendMessage(msg: string) {
    if (msg.length > 0 && msg.length < 251) {
      this.showSpinner = true;
      this.errorMessage = null;
      let message: Message = new Message();
      message.sender = 'You';
      message.question = msg;
      message.isQuestionType = true;
      this.messages.push(message);
      this.chat_message.nativeElement.value = '';
      this.openAiRepository.askQuestion(message).subscribe((data) => {
        this.messages.push(data);
        this.showSpinner = false;
        this.scrollToBottom();
      });
    } else {
      this.errorMessage = "Please enter between 1 and 250 characters only. Thanks.";
    }
  }

  isUserEmailExist(): boolean {
    if (_.isEmpty(this.userRepository.getUser().email)) {
      return false;
    }
    return true;
  }
}

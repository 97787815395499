
export class Feedback{


  constructor(
    public id?: number,
    public email?: string,
    public preferLect?: string,
    public timeConTest?: string,
    public preferSubAmo?: string,
    public acceptForCont?: boolean,
    public comment?: string
  ){}

}

<app-nav-bar></app-nav-bar>
<div class="container-fluid">
  <div class="container-fluid bg-white">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 col-sm-12">

      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 col-sm-12">
        <h2>About Us</h2>
        <p>
          High Tech District (registered as HIGHTECHDISTRICT.COM LIMITED) is a dedicated educational platform focused on delivering high-quality computer science content through interactive learning experiences. We empower students and professionals with structured courses, hands-on coding exercises, and AI-driven assistance to enhance their learning journey.
         </p>
         <p>
          As part of our commitment to innovation and growth in the EdTech space, we are proud members of EdTech NZ, aligning with a network of educators, technologists, and industry leaders shaping the future of education in New Zealand and all over the world.
        </p>
        <p>
          We also receive valuable support from Startup Aotearoa and the Ministry of Awesome, two leading organizations that help foster innovation and entrepreneurship. Their mentorship and resources have played a key role in our journey to refine and expand High Tech District.
         </p>
         <p>
          With these strong partnerships, we continue to improve our platform, ensuring that learners receive high-quality, industry-relevant education that prepares them for real-world challenges.
         </p>
      </div>
      <div class="col-md-2"></div>
    </div>
    <br/><br/>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-4 p-1">
        <h2>Memberships</h2>
        <a href="https://edtechnz.org.nz/our-members/" target="_blank"><img class="square-image" src="../../assets/EdTechNZ.png" /></a>
      </div>
      <div class="col-md-4 p-1">
        <h2>Mentorships</h2>
        <a href="https://startupaotearoa.org/" target="_blank"><img class="square-image" src="../../assets/startup_aotearoa.JPG" /></a>
        &nbsp; &nbsp;
        <a href="https://ministryofawesome.com/" target="_blank"><img class="square-image" src="../../assets/MOA_blue_logo.JPG" /></a>

      </div>

      <div class="col-md-2 p-1">

      </div>

    </div>

  </div>

  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

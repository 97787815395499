<app-nav-bar></app-nav-bar>
<div class="container-fluid">

    <!-- Intro-->
    <div class="row bg-white" id="main-container">
      <div class="row fullWidth">
       <div class="col-sm-8 col-md-6">
        <div class="row secondary-content">
          <div class="row">
            <img src="../../assets/Intro_Text.jpg"/>


          </div>
        </div>

      </div>
      <div class="col-sm-4 col-md-5">
        <img src="../../assets/Read_Code.jpg"/>
      </div>
     </div>
     <div class="row fullWidth">
      <p>&nbsp;</p>
     </div>
     <div class="row fullWidth">
      <div class="col-sm-3 col-md-1">
        <!--This is just to push main content to the middle in wider screen-->
      </div>
      <div class="col-sm-8 col-md-8 fullWidth">

        <button
            class="btn btn-success" (click)="navigateToSignUp('Online Tech Uni Section')"> Sign Up</button> &nbsp; <button
            class="btn btn-success" (click)="navigateToLearningPath('Online Tech Uni Section')"> Learning Path</button>
      </div>
      <div class="col-sm-1 col-md-3">

      </div>
     </div>
    </div>

    <!-- Weekly Quiz-->
    <div class="row bg-white" id="main-container">
      <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-1">
        <!--This is just to push main content to the middle in wider screen-->
      </div>
      <div class="col-sm-10 col-md-8">
        <div class="row secondary-content">
          <!-- Since title is only taking small part of the row, whenever second row has only one article that can
          fit the remaining available space of first row then content from second row is jumping up a bit. fullWidth is
          helping to mitigate the problem-->
          <div class="row fullWidth">
            <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting','Courier New', Courier, monospace;">
              <i class="fa fa-code"></i> Test Your Skills: Weekly Challenges  <i class="fa fa-code"></i>
            </h1>
          </div>
          <!-- <div class="row">
            <div class="col-sm-2">
            </div>
            <div class="col-sm-8">
              <h2>View Posted Coding Answers</h2>
            </div>
            <div class="col-sm-2">
            </div>
          </div> -->
          <div class="row">
            <app-weekly-quiz-wrapper></app-weekly-quiz-wrapper>
          </div>
        </div>


        <div style="clear: both;"></div>

      </div>
      <div class="col-sm-2 col-md-3">
      </div>
    </div>

  <!-- Latest Article Row-->
  <div class="row bg-grey" id="main-container">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">
      <div class="row">
        <h1 style="text-decoration: underline; font-family:'Lucida Handwriting', 'Courier New', Courier, monospace;">Our Latest Articles and Videos</h1>
      </div>
      <div class="row main-content">
        <div class="row">
          <div class="col-sm-8 col-md-8">
            <div class="container-sm mt-3  remove-margin-padding round-top-borders" >
                <!-- Header section -->
                <div class="header bg-purple text-white p-2 remove-margin-padding  round-top-borders"
                [class.bg-purple]="articles[0]?.lectureType===LocalLectureType.Article" [class.bg-blue]="articles[0]?.lectureType===LocalLectureType.Video" >
                  <h2 *ngIf="articles[0]?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                  <h2 *ngIf="articles[0]?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                </div>
             </div>
            <div class="card bg-light remove-margin-padding ">
              <img  *ngIf="articles[0]?.images[0]?.location"
                src="{{articles[0]?.images[0]?.location}}" alt="article image">
              <img *ngIf="(!articles[0]?.images[0]?.location) && (articles[0]?.lectureType===LocalLectureType.Article)"
                src="../../assets/AI.JPG" alt="article image">
              <img *ngIf="(!articles[0]?.images[0]?.location) && (articles[0]?.lectureType===LocalLectureType.Video)"
                 src="../../assets/VideoLecture_Icon.jpg" alt="article image">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <h2 class="card-title">{{articles[0]?.title}}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <h3 class="card-title">Author: {{articles[0]?.user?.username}}</h3>
                  </div>
                </div>
                <p class="card-text">{{articles[0]?.introduction?.slice(0,65)}}...</p>
                <div class="row">
                  <div class="col-md-6"><a [routerLink]="['/articleDetail', articles[0]?.id ]"
                      class="btn btn-primary">More Details</a> </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-sm-4 col-md-4">
            <h2>{{articles[0]?.title}}</h2>
            <p>{{articles[0]?.introduction?.slice(0,65)}}...</p>
            <h3>Author: {{articles[0]?.user?.username}}</h3>
          </div>

        </div>

        <div class="row">
          <ng-template ngFor let-article [ngForOf]="articlesPerPage" ; let-i="index">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="container-sm mt-3  border remove-margin-padding round-top-borders">
                <!-- Header section -->
                <div class="header bg-purple text-white p-2  remove-margin-padding round-top-borders"
                [class.bg-purple]="article?.lectureType===LocalLectureType.Article" [class.bg-blue]="article?.lectureType===LocalLectureType.Video" >
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                </div>
                <div class="card  remove-margin-padding">
                  <img *ngIf="article?.images[0]?.location"  src="{{article?.images[0]?.location}}"
                    alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Article)"
                     src="../../assets/AI.JPG" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Video)"
                    src="../../assets/VideoLecture_Icon.jpg" alt="article image">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-10">
                        <h2 class="card-title">{{article?.title}}</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <h3 class="card-title">Author: {{article?.user?.username}}</h3>
                      </div>
                    </div>
                    <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                    <div class="row">
                      <div class="col-md-6"><a class="btn btn-primary" (click)="navigateToArticle(article?.id)">More
                          Details</a> </div>
                    </div>
                  </div>

                </div>
             </div>


            </div>
          </ng-template>

        </div>


      </div>

      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageArticleCount" (click)="changeArticlePage(page)"
          class="btn btn-outline-primary" [class.active]="page == articleSelectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>



    </div>
    <div class="col-sm-2 col-md-3">
      <!-- <div class="card m-1 p-1 bg-light">
        <img class="card-img-top" src="../../assets/crypto_com.JPG" alt="Card image cap">
        <div class="card-body">
          <div class="row">
            <div class="col-md-10">
              <h5 class="card-title">Interested in staking crypto coin?</h5>
            </div>

          </div>
          <p class="card-text">Join crypto.com exchange and stake cro. Use referal code esqk2y962e to get bonus.
            Please invest responsibly</p>
          <div class="row">
            <div class="col-md-6"><a href="https://crypto.com/exch/esqk2y962e" target="_blank"
                class="btn btn-primary">More Detais</a> </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>



  <!-- Case Study Article Row-->
  <div class="row bg-white" id="main-container">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">
      <div class="row secondary-content">
        <!-- Since title is only taking small part of the row, whenever second row has only one article that can
        fit the remaining available space of first row then content from second row is jumping up a bit. fullWidth is
        helping to mitigate the problem-->
        <div id="caseStudyViewLocation"></div>
        <div class="row fullWidth">
          <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting','Courier New', Courier, monospace;">Our Case Studies</h1>
        </div>
        <div class="row">
          <ng-template ngFor let-caseStudy [ngForOf]="caseStudiesPerPage">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="container-sm mt-3  border remove-margin-padding round-top-borders">
                <!-- Header section -->
                  <div class="header bg-purple text-white p-2  remove-margin-padding round-top-borders"
                  [class.bg-purple]="caseStudy?.lectureType===LocalLectureType.Article" [class.bg-blue]="caseStudy?.lectureType===LocalLectureType.Video" >
                    <h2 *ngIf="caseStudy?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                    <h2 *ngIf="caseStudy?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                  </div>
                  <div class="card  remove-margin-padding">
                    <img *ngIf="caseStudy?.images[0]?.location" class="card-img-top"
                      src="{{caseStudy?.images[0]?.location}}" alt="product image">
                      <img *ngIf="(!caseStudy?.images[0]?.location) && (caseStudy?.lectureType===LocalLectureType.Article)"
                      class="card-img-top" src="../../assets/AI.JPG" alt="article image">
                    <img *ngIf="(!caseStudy?.images[0]?.location) && (caseStudy?.lectureType===LocalLectureType.Video)"
                      class="card-img-top" src="../../assets/VideoLecture_Icon.jpg" alt="article image">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-10">
                          <h2 class="card-title">{{caseStudy?.title}}</h2>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-10">
                          <h3 class="card-title">Author: {{caseStudy?.user?.username}}</h3>
                        </div>
                      </div>
                      <p class="card-text">{{caseStudy?.introduction?.slice(0,65)}}...</p>
                      <div class="row">
                        <div class="col-md-6"><a class="btn btn-primary" (click)="navigateToCaseStudy(caseStudy?.id)">More
                            Details</a> </div>
                      </div>
                    </div>

                  </div>
                </div>

            </div>
          </ng-template>
        </div>
      </div>

      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCaseStudyCount" (click)="changeCaseStudyPage(page)"
          class="btn btn-outline-primary" [class.active]="page == caseStudiesSelectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>

<!--
      <div>
        <hr />
      </div> -->

    </div>
    <div class="col-sm-2 col-md-3">
    </div>
  </div>

   <!-- Feature Row-->
   <div class="row bg-grey" id="main-container">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">


      <div class="row secondary-content">
        <!-- Since title is only taking small part of the row, whenever second row has only one article that can
        fit the remaining available space of first row then content from second row is jumping up a bit. fullWidth is
        helping to mitigate the problem-->
        <div id="featuresPromoViewLocation"></div>
        <div class="row fullWidth">
          <div class="col-sm-1"></div>
          <div class="col-sm-10 col-md-12">
            <h1 style="text-decoration: underline; font-family:'Lucida Handwriting', 'Courier New', Courier, monospace;">Our Features</h1>
          </div>
          <div class="col-sm-1"></div>


        </div>

        <div class="row fullWidth">
          <div class="col-sm-1"></div>
          <div class="col-sm-10 col-md-12">
            <div class="object-wrapper">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/H61Of0gx9PI?si=VMngOHzpz5MeLmv_"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-sm-1"></div>

        </div>

        <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10 col-md-12">
            <h3> &nbsp;</h3>
          </div>
          <div class="col-sm-1"></div>
        </div>



        <div class="row fullWidth">
          <div class="col-md-12">
            <mat-card id="feature-matcard-container">
              <h2 style="color: white;"> You don't need credit card (<i class="fa fa-credit-card"></i>), to register with us. If you have an account with us:</h2>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-4 col-md-4">
                    <mat-card id="feature-matcard-item">
                      <img src="../../assets/post_comment.jpg"/>
                      <h2>Post comments</h2>
                      <p> You can post questions on comment sections.</p>

                    </mat-card>
                  </div>
                  <div class="col-sm-4 col-md-4">
                    <mat-card id="feature-matcard-item">
                      <img src="../../assets/Ai_Assistant.jpg"/>
                      <h2>AI Assistant</h2>
                      <p> Our AI Assistant can answer your questions, help you with your coding challenges and help navigate around ...</p>

                    </mat-card>
                  </div>
                  <div class="col-sm-4 col-md-4">
                    <mat-card id="feature-matcard-item">
                      <img src="../../assets/practise.jpg"/>
                      <h2>Practise and more ...</h2>
                      <p> You can practise Multiple-Choice and Coding challenges.</p>

                    </mat-card>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-md-4">
                    <mat-card id="feature-matcard-item">
                      <img src="../../assets/Share_Badges.JPG"/>
                      <h2>Share Achievements</h2>
                      <p> You can share your profile and badges...</p>

                    </mat-card>
                  </div>
                </div>

              </div>
              <br/>
              <button
              class="btn btn-success" (click)="navigateToSignUp('Our Feature Section')"> Sign Up</button>
            </mat-card>
            <br/>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3">
          </div>
          <div class="col-sm-9 my-auto">
              <div class="carouselNavbar">
                <ul>

                  <li id="linoDecor">
                    <a style="cursor: pointer;" (click)="onPreviousClick()"> <span class="arrow left"></span></a>
                  </li>
                  <li id="linoDecor">

                    <div class="carousel">
                      <img class="mobileFrameImage" src="../../assets/phone.jpg"/>
                      <ng-container *ngFor="let featureImage of featureImages; let i = index">

                          <p *ngIf="i === currentSlide" @roateImage class="slide">
                            <img class="animationImage" src="{{featureImage.url}}" alt="Card image cap" />
                            <br>
                            <span class="slide-after"> <strong>-{{ featureImage.caption}}-</strong></span>
                          </p>



                      </ng-container>

                    </div>
                  </li>
                  <li id="linoDecor">
                    <a style="cursor: pointer;" (click)="onNextClick()"> <span class="arrow right"></span></a>
                  </li>
                </ul>
              </div>
          </div>

        </div>


      </div>


    </div>
    <div class="col-sm-2 col-md-3">
    </div>
  </div>



  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

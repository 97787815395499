<div class="container-fluid">

  <!--
  <div class="row" id="main-container" [style.height]="getOptimalMainContainerHeight()">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-2">

    </div>-->

 <!-- This is for wider screen like laptop phone-->
 <div class="row">
    <div class="col-sm-4 col-md-4">

    </div>
    <div class="col-sm-8 col-md-8">

    </div>

  </div>
  <div  class="row" id="main-container">

    <div class="col-sm-12 col-md-12">


      <div class="row" *ngIf="isCompleted===false" >
        <div class="col-sm-5">
          <div class="row">
            <div class="quizTips">
              <h2>Multiple-Choice <i class="fa fa-question"></i></h2>
               <div class="quizTipsItem">
                 <table class="myaccount-menu-table">
                   <tr>
                    <td class="tip-item" >
                      <ul>
                        <li>
                          Each multiple-choice quiz is paired with a coding quiz to reinforce your understanding.
                          &nbsp;   &nbsp;
                        </li>
                        <li>
                         Complete the multiple-choice quiz, then solve the coding quiz to apply what you’ve learned!
                         &nbsp;   &nbsp;
                       </li>

                        <li>
                          Some challenges only have either multiple-choice quiz or coding quiz.
                        </li>
                      </ul>

                   </td>
                   </tr>
                 </table>
               </div>
              </div>
          </div>
          <div class="row">
            <app-weeklyquiz [quizType]="localQuestionType.Multiple_Choice_Quiz"></app-weeklyquiz>
          </div>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <div class="row">
            <div class="quizTips">
              <h2>Coding <i class="fa fa-question"></i></h2>
               <div class="quizTipsItem">
                 <table class="myaccount-menu-table">
                   <tr>
                    <td class="tip-item" >
                     <ul>
                       <li>
                         Each multiple-choice quiz is paired with a coding quiz to reinforce your understanding.
                         &nbsp;   &nbsp;
                       </li>
                       <li>
                        Complete the multiple-choice quiz, then solve the coding quiz to apply what you’ve learned!
                        &nbsp;   &nbsp;
                      </li>

                       <li>
                         Some challenges only have either multiple-choice quiz or coding quiz.
                       </li>
                     </ul>

                   </td>
                   </tr>
                 </table>
               </div>
              </div>
          </div>
          <div class="row">
            <app-weeklyquiz [quizType]="localQuestionType.Coding_Quiz"  ></app-weeklyquiz>
          </div>
        </div>

      </div>
      <div class="row" *ngIf="isCompleted===true" >


        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <div class="main-question">
            <div class="card-body bg-success text-white border rounded">

              <h2 style="display: block;"> Love <i class="fa fa-heart"></i> Coding Challenges? There’s more waiting for you!</h2>
              <h3>   Please <button
                class="btn btn-primary m-1" (click)="navigateToMyAccount('Weekly Quiz Section')"> Login </button> &nbsp; or &nbsp; <button
                class="btn btn-primary m-1" (click)="navigateToSignUp('Weekly Quiz Section')"> Sign Up</button> to unlock extra multiple-choice and coding exercises.</h3>

            </div>
          </div>
        </div>

      </div>
      <br/>
      <div class="row fullWidth">
        <div class="col-sm-5"></div>
        <div class="col-sm-5">
          <button class="btn btn-primary m-1" *ngIf="isCompleted===false" (click)="next()">Next Question</button>
          &nbsp; &nbsp;
          <button class="btn btn-primary m-1" (click)="restart()">Restart</button>
        </div>
        <div class="col-sm-2"></div>
      </div>

      <br/> <br/>


    </div>

  </div>
</div>

<div class="container-fluid">

  <div class="row bg-dark">
    <div class="container">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>

  <div class="container-fluid bg-white">
    <div class="row">
      <div class="col-md-2">
        <!-- If there are next and previous articles then display navigation buttons  -->
        <ng-template ngFor let-navArticle [ngForOf]="navArticleList">
          <button *ngIf="navArticle?.id !== null && navArticleList.length > 1" type="button" class="btn-block"
            [class.btn-success]="navArticle?.id == article.id" [class.btn-secondary]="navArticle?.id !== article.id"
            (click)="navigateToNextArticle(navArticle?.id)">
            <i *ngIf="navArticle?.lectureType == LocalLectureType.Video" class="fa fa-video"></i>
            {{navArticle?.title?.slice(0,35)}}...
            <i *ngIf="navArticle?.premium  && (!isAdminUser()&&!isPremiumUser())" class="fa fa-lock"></i>
            <i *ngIf="navArticle?.premium && (isAdminUser() || isPremiumUser())" class="fa fa-lock-open"></i>
          </button>

        </ng-template>
      </div>
      <div id="main-container"
        *ngIf="(article?.publish && !article?.premium) || (article?.publish && article?.premium && (isAdminUser() || isPremiumUser()) )"
        class="col-md-8 col-sm-12">
        <div class="row">
          <h1>{{article.title}}</h1>
        </div>
        <div class="row">
          <h4 style="font-style: italic;">Published Date: {{getTrimmedPublishDate()}},&nbsp;Last Edited
            Date:{{getTrimmedLastEditedDate()}},</h4>
          <h4 style="font-style: italic;">&nbsp; Author: {{article?.user?.username}}</h4>
        </div>
        <div *ngIf="article.lectureType == this.LocalLectureType.Article" class="row">
          <h4 style="font-style: italic;  cursor: pointer; color: blue;" (click)="askAIAssistantSummary()">
            Ask AI Assistant  for Summary of this Article... <i class="fa fa-robot"></i> <mat-spinner *ngIf="showSpinner" diameter="30"></mat-spinner>
          </h4>
        </div>
        <div class="row">
          <!-- <p class="identText">{{article.introduction}}</p> -->
          <p class="identText" [innerHTML]="article.introduction"></p>
        </div>

        <div class="row fullWidth" *ngIf="article.lectureType == this.LocalLectureType.Video">
          <div class="col-sm-1"></div>
          <div class="col-sm-10 col-md-12">
            <div class="object-wrapper">
              <iframe width="560" height="315" [src]="safeUrl" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-sm-1"></div>
        </div>
        <div *ngIf="article.lectureType == this.LocalLectureType.Article">
          <div class="row">
            <div class="col-sm-5 col-md-5">
              <figure>
                <img *ngIf="article.images[0] != null" src="{{article?.images[0]?.location}}" />
                <img *ngIf="article.images[0] == null" src="{{getRandomQuote()}}" />
                <figcaption> Image One</figcaption>
              </figure>
            </div>
            <div class="col-sm-7 col-md-7">
              <p class="identText" [innerHTML]="article.firstParagraph"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-7 col-md-7">
              <p class="identText" [innerHTML]="article.secondParagraph"></p>
            </div>
            <div class="col-sm-5 col-md-5">
              <figure>
                <img *ngIf="article.images[1] != null" src="{{article?.images[1]?.location}}" />
                <img *ngIf="article.images[1] == null" src="{{getRandomQuote()}}" />
                <figcaption>Image Two</figcaption>
              </figure>
            </div>
          </div>
        </div>

        <div *ngIf="article.conclusion != null" class="row">
          <p class="identText" [innerHTML]="article.conclusion"></p>
          <div *ngIf="article.recTest != null">
            <p class="fullWidth"><i class="fa fa-bullhorn"></i> <b> Recommended Multiple-Choice Test:</b></p>
            <ul>
              <li> {{article.recTest}} </li>
            </ul>
          </div>
        </div>

      </div>
      <div id="main-container" *ngIf="(article?.publish && article?.premium && !(isAdminUser() || isPremiumUser()) )"
        class="col-md-8 col-sm-12">
        <div class="row">
          <h1>{{article.title}}</h1>
        </div>
        <div class="row">
          <h4 style="font-style: italic;">Published Date: {{getTrimmedPublishDate()}},&nbsp;Last Edited
            Date:{{getTrimmedLastEditedDate()}},</h4>
          <h4 style="font-style: italic;">&nbsp;Author: {{article?.user?.username}}</h4>
        </div>
        <div class="row">
          <h3 class="identText" *ngIf="user.id == null">{{article?.introduction?.slice(0,65)}}....... Please <a
              routerLink="/myaccount">login</a> or subscribe to access remaining content of this article </h3>
          <h3 class="identText" *ngIf="user.id != null"> {{article?.introduction?.slice(0,65)}}....... Please <a
              routerLink="/myaccount/main/payment">subscribe</a> to access remaining content of this article </h3>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-5 col-md-4">
            &nbsp;
          </div>
          <div class="col-sm-7 col-md-8">
            <i class="fa fa-lock fa-5x"></i>
          </div>
        </div>

      </div>
      <div id="main-container" *ngIf="!article.publish" class="col-md-8 col-sm-12">
        <div class="row">
          <div class="col-sm-5 col-md-4">
          </div>
          <div class="col-sm-7 col-md-8">
            <h3> Sorry this page is currently under maintenance. <i class="fa fa-screwdriver-wrench"></i> </h3>
          </div>
        </div>

      </div>
      <div class="col-md-2"></div>
    </div>


    <div class="row">
      <div class="col-md-2"> </div>
      <div class="col-sm-12 col-md-8">
        <a (click)="navigateToPreviousPage()" class="btn btn-primary">Go Back</a><span>&nbsp;</span>
        <button *ngIf="article?.previousArticle?.id !== null" type="button" class="btn btn-outline-success"
          (click)="navigateToNextArticle(article?.previousArticle?.id)">...<i class="fa fa-arrow-left"></i>...
          </button> <span>&nbsp;</span>
        <button *ngIf="article?.nextArticle?.id !== null" type="button" class="btn btn-outline-success"
          (click)="navigateToNextArticle(article?.nextArticle?.id)">...<i class="fa fa-arrow-right"></i>...
        </button>
      </div>
      <div class="col-md-2"></div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-sm-12 col-md-8">
        <app-aichat></app-aichat>
      </div>
      <div class="col-md-2"></div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-sm-12 col-md-8">
        <p style="font-style: italic;">* Please note that the information on this page may not be accurate or uptodate.
          Also it is important to review the terms and conditions provided by any third parties links included. For more
          details, please refer to our
          <a href="#" (click)="openTermsDialog($event)">privacy policy.</a>
        </p>
      </div>
      <div class="col-md-2"></div>
    </div>



    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-sm-12 col-md-8">

        <div class="bg-danger mt-2 p-2 text-center text-white" *ngIf="errorMessage != null">
          Error: {{errorMessage}}
        </div>

        <div class="container-sm my-3 p-2 bg-success text-white border rounded">

          <span *ngIf="user.email">
            <form novalidate #form="ngForm" (ngSubmit)="postComment(form)">
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label for="comment">You can post your comment here. Please be respectful.</label>
                  <textarea class="form-control" id="description" name="description" [(ngModel)]="comment.description"
                    required minlength="10" maxlength="140" required #description="ngModel"></textarea>

                </div>
                <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert">
                  <div> Comment is required to have minimum 10 and maximum 140 words. </div>
                </div>

              </div>

              <div class="text-center">
                <button class="btn btn-primary m-1" type="submit">Post</button>
              </div>
            </form>
          </span>

          <span *ngIf="!user.email">
            <div class="row">
              <div class="col-sm-12">
                <p>If you have account with us, you can post comments and also practise exercises on these topics.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-1">
                <a class="nav-link" style="cursor: pointer; color: blue;"
                  (click)="navigateToMyAccountLogin()">MyAccount</a>
              </div>
              <div class="col-sm-1">
                <a class="nav-link" style="cursor: pointer; color: blue;" routerLink="/registration">Registration</a>
              </div>
              <div class="col-sm-10">
              </div>
            </div>
          </span>

        </div>

        <table class="table table-sm">
          <thead>
            <tr>
              <th>
                <p style="font-size: 24px;">Comments</p>
              </th>
            </tr>
          </thead>
          <tbody>

            <tr *ngIf="article.comments.length == 0">
              <td colspan="5">There are no comments</td>
            </tr>

            <ng-template ngFor let-c [ngForOf]="article.comments">
              <tr>
                <td>{{c.description }} --{{c.user?.username}}</td>
                <span *ngIf="user.email">
                  <td>
                    <span *ngIf="user.id == c.user.id">
                      <i class="fas fa-pencil-alt hover-pointer" aria-hidden="true"
                        (click)="openDialog(c.id, 0,'edit', c.description)"></i> |
                    </span>
                    <i class="fas fa-reply hover-pointer" aria-hidden="true" (click)="openDialog(c.id, 0,'reply')"></i>
                  </td>
                </span>
                <td>
                  <i class="fas fa-plus hover-pointer" aria-hidden="true"
                    (click)="getRepliesByArticleAndCommentId(article.id,c.id)"> Replies... </i>
                </td>
              </tr>
              <span *ngIf="showRepliesOfComment == c.id && replyExist">
                <ng-template ngFor let-r [ngForOf]="replies">
                  <tr>
                    <td>
                      <p class="text-primary"> {{r.description}} --{{r.user?.username}}
                        <span *ngIf="user.id == r.user.id">
                          <i class="fas fa-pencil-alt hover-pointer" aria-hidden="true"
                            (click)="openDialog(c.id, r.id, 'edit', r.description)"></i>
                        </span>
                      </p>
                    </td>
                  </tr>
                </ng-template>
              </span>
              <span *ngIf="showRepliesOfComment == c.id && !replyExist">

                <tr>
                  <td>
                    <p class="text-primary"> There is no reply for this comment yet.
                  </td>
                </tr>

              </span>
              <!-- <tr *ngFor="let line of o.cart.lines">
              <td></td>
              <td></td>
              <td></td>
              <td>{{line.product.name}}</td>
              <td>{{line.itemCount}}</td>
              <td>{{line.linePrice}}</td>
            </tr> -->
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>

  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

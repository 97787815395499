import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AISummaryData} from '../../model/aisummary.model';


@Component({
  selector: 'app-aisummarydialog',
  templateUrl: './aisummarydialog.component.html',
  styleUrls: ['./aisummarydialog.component.css']
})
export class AisummarydialogComponent  {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AISummaryData) {}

}

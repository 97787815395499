import {APP_INITIALIZER, NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavBarComponent } from "./nav-bar.component";
import { RouterModule} from "@angular/router";
import { FormsModule } from "@angular/forms";
import { FooterComponent } from "./footer/footer.component";
import { AichatComponent } from './aichat/aichat.component';
import { MatExpansionModule} from "@angular/material/expansion";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, MatExpansionModule, MatProgressSpinnerModule],
  declarations: [NavBarComponent, FooterComponent, AichatComponent],
  exports: [NavBarComponent, FooterComponent, AichatComponent]
})
export class NavBarModule {}

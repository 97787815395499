<section class="gallery">
  <div class="container-fluid">
    <div class="img-container">
      <img src="{{getFirstImage()?.location}}" alt="Product_Image" id="preview">


        <div class="img-prev text-center">
            <ng-template ngFor let-image [ngForOf]="getImages()" let-i="index">
              <div *ngIf="i == 0" class="img-slide active">
                <img  src="{{image?.location}}" alt="Product_Image" imageGallery>
              </div>
              <div *ngIf="i > 0" class="img-slide">
                <img  src="{{image?.location}}" alt="Product_Image" imageGallery>
              </div>
            </ng-template>
        </div>

</div>
  </div>

</section>

<div class="container-fluid">

  <div class="row bg-dark">
    <div class="container">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>

  <div class="container-fluid bg-white">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 col-sm-12">
        <h3>This page is dedicated to expressing our gratitude to the authors and creators whose articles, books, images, and other materials have been referenced and utilized in this application.</h3>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 col-sm-12">
        <h2>Images</h2>
       <p>Mobile frame image for carousel </p>
       <ul>
        <li>Attribution link 	<a href="https://www.freepik.com" target="_blank">Image by svstudioart</a> on Freepik</li>
       </ul>
      </div>
      <div class="col-md-2"></div>
    </div>
    <br/><br/>

    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 col-sm-12">
        <h2>Books, Articles and Online Resources</h2>
       <p> </p>
       <ul>
        <li>Pro Angular 9 Build Powerful and Dynamic Web Apps by Adam Freeman, Fourth Edition</li>
        <li>2016_Book_CSSMastery by Andy Budd and Emil Bjorklund, Third Edition</li>
        <li>Data Structures and Algorithms Made Easy by Narasimha Karumanchi, 2017 Edition</li>
        <li>Grokking Algorithms By Aditya Y Bhargava</li>
        <li>System Design Interview - An insider's guide By Xu, Alex</li>
        <li><a href="https://www.oracle.com/" target="_blank">Oracle</a> </li>
        <li><a href="https://javascript.info/" target="_blank">JavaScript Info</a> </li>
        <li><a href="https://www.w3schools.com/" target="_blank">W3 School</a></li>
       </ul>
      </div>
      <div class="col-md-2"></div>
    </div>
    <br/><br/>



  </div>

  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

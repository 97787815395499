<div class="container-fluid">
 <!-- This is for wider screen like laptop phone-->
  <div  class="row" id="main-container">

    <div class="col-sm-12 col-md-12">

      <div class="row main-question">


              <div class="card-body bg-success text-white border rounded">

                <div class="row">
                  <p class="text-warn" *ngIf="errorMessage != null">
                    <i style="color: red; font-weight: bold;">X</i> &nbsp; {{errorMessage}}
                  </p>
                  <p class="text-warn" *ngIf="successMessage != null">
                    {{successMessage}} &nbsp; <i class="fa fa-check text-blue"></i>
                  </p>
                </div>
                <div class="row" *ngIf="!complete && question != null">
                  <div class="col-sm-12">
                    <h2 style="color: Cyan; margin-bottom: 1px;">Level: &nbsp;{{getSkillLevelValue()}}</h2>

                    <h2 style="color: Cyan; margin-top: 0px;">Subject: &nbsp;{{ subCategory}}</h2>
                  </div>
              </div>

                <div class="row" *ngIf="!complete">
                    <h2>{{question?.content}}</h2>
                </div>
              <div class="row" *ngIf="question === null && quizType === localQuestionType.Multiple_Choice_Quiz">
                  <h3>No multiple-choice quiz for this challenge. </h3>
              </div>
              <div class="row" *ngIf="question === null && quizType ===  localQuestionType.Coding_Quiz">
                <h3>No coding quiz for this challenge. </h3>
              </div>
                <div class="row" *ngIf="complete">
                  <div class="col-sm-2 col-md-2"></div>
                  <div class="col-sm-8 col-md-8">
                    <p> <i class="fa fa-clipboard-check"></i> You have successfully completed this exercise. Well done <i class="fa fa-thumbs-up"></i></p>
                  </div>
                  <div class="col-sm-2 col-md-2"></div>
                </div>
                <ng-template ngFor let-questionOption [ngForOf]="question?.options" *ngIf="!complete && question?.questionType == localQuestionType.Multiple_Choice_Quiz">
                  <div class="row">
                    <label>
                      <input  type="radio"
                             name="option"
                             [value]="questionOption.content"
                             [(ngModel)]="selectedOption">
                             {{ questionOption.content }}
                    </label>
                  </div>

                </ng-template>
                <div class="row" *ngIf="explanation != null && question?.questionType == localQuestionType.Multiple_Choice_Quiz" >
                  <p class="text-warn">  {{explanation}} &nbsp; <i class="fa fa-check text-blue"></i></p>
                </div>
                <div class="row" *ngIf="!complete && question?.questionType == localQuestionType.Coding_Quiz" >


                    <!-- <h4 style="font-style: italic;" class="text-black" (click)="askAIAssistantHint()">
                      AI Assistant hints...<i class="fa fa-lightbulb"></i> <mat-spinner *ngIf="showSpinner" diameter="30"></mat-spinner>
                    </h4> -->

                  <textarea id="chat_message" #chat_message name="chat_message" type="text" autocomplete="off"
                  placeholder="Type your code here..." [(ngModel)]="codeArea" minlength="1" maxlength="900" rows="10"
                  data-toggle="tooltip" title="Please enter between 1 and 900 characters. Don't alter method signature."></textarea>
                  <div class="bg-danger mt-2 p-2 text-center text-white fullWidth" *ngIf="errorMessage != null">
                    Error: {{errorMessage}}
                  </div>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <p>Your code will be tested against following test cases... &nbsp;</p> <p *ngIf="this.successMessage"><i class="fa fa-check text-blue"></i></p>
                      </mat-panel-title>
                      <mat-panel-description>

                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul>
                      <li *ngFor="let testCase of question?.options[0].testcases">
                        <h3><b> {{testCase.description}} </b></h3>
                        <h3>
                          <span>Expected Input: </span>
                          <span *ngFor="let parameter of testCase.parameters">
                            <span>{{parameter.inputValue}} </span> <span>,</span>
                          </span>
                        </h3>
                        <h3>Expected Output: {{ testCase.expectedOutput}}</h3>
                      </li>
                    </ul>
                    <p *ngIf="articles.length <= 0">Sorry there is no suggested articles for this exercise.</p>

                  </mat-expansion-panel>


                </div>
                <div class="row" >
                  <div class="col-sm-6 col-md-8">
                    <button class="btn btn-primary m-1" *ngIf="!complete && question?.questionType == localQuestionType.Multiple_Choice_Quiz" (click)="checkAnswer()">Check Answer</button>
                    <button class="btn btn-primary m-1" *ngIf="!complete &&  question?.questionType == localQuestionType.Coding_Quiz" (click)="compileCode()">Compile & Run Code</button>
                  </div>
                  <div class="col-sm-5 col-md-2">
                  </div>
                  <div class="col-sm-1 col-md-2">
                  </div>
                </div>

              </div>
              <div *ngIf="!complete" class="fullWidth">
                <p> <i class="fa fa-bullhorn"></i> Want to level up? Log in to your account for more practice exercises!</p>
              </div>

     </div>
    </div>
  </div>
</div>

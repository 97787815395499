import {Injectable} from "@angular/core";
import {ApiResponse} from "./apiResponse.model";
import {Feedback} from "./feedback.model";
import {RestDataSource} from "./rest.datasource";
import { Observable} from "rxjs";




@Injectable({
  providedIn: 'root'
})
export class FeedbackRepository {

  constructor(private dataSource: RestDataSource) {}

  createFeedback(feedback: Feedback): Observable<ApiResponse<null>> {
      return this.dataSource.createFeedback(feedback);
  }

}

import {Injectable} from "@angular/core";
//import {StaticDataSource} from "./static.datasource";
import {RestDataSource} from "./rest.datasource";
import { Observable } from "rxjs";
import { GraphData } from "./graph.model";



@Injectable({
  providedIn: 'root'
})
export class GraphRepository {
  private graphData: GraphData;


  constructor(private dataSource: RestDataSource) {

  }


  getLearningPathGraph(name: string): Observable<GraphData>{
    return new Observable(observer => {

      this.dataSource.getLearningPathGraph(name).subscribe(data => {
        // console.log("In Graph Repo"+JSON.stringify(data));
        this.graphData = data;
        observer.next(this.graphData);
        observer.complete();
      });


    });
  }







}

<div class="container-fluid">

  <div class="row bg-dark">
    <div class="container">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>

  <div class="container-fluid bg-white">
    <div class="row">
       <div id="scrollhere"></div>
      <div class="col-sm-5">
       <app-gallery></app-gallery>
      </div>
      <div class="col-sm-7">
      <div>
        <div class="row">
          <div *ngIf="getWindowInnerWidth() > 780px" class="col-sm-3 text-right">Name: </div>
          <div *ngIf="getWindowInnerWidth() < 780px" class="col-sm-3 "><p style="font-style: italic;font-weight: bold;">Name:</p> </div>
          <div class="col-sm-4">{{product.name}} </div>
          <div class="col-sm-2 text-left"></div>
          <div class="col-sm-3"> </div>
        </div>
        <div class="row">
          <div *ngIf="getWindowInnerWidth() > 780px" class="col-sm-3 text-right">Review Published Date: </div>
          <div *ngIf="getWindowInnerWidth() < 780px" class="col-sm-3 "><p style="font-style: italic;font-weight: bold;">Review Published Date:</p></div>
          <div class="col-sm-4">{{getTrimmedPublishDate()}} </div>
          <div class="col-sm-2 text-left"></div>
          <div class="col-sm-3"> </div>
        </div>
        <div class="row">
          <div *ngIf="getWindowInnerWidth() > 780px" class="col-sm-3 text-right">Review Last Edited Date: </div>
          <div *ngIf="getWindowInnerWidth() < 780px" class="col-sm-3 "><p style="font-style: italic;font-weight: bold;">Review Last Edited Date:</p></div>
          <div class="col-sm-4">{{getTrimmedLastEditedDate()}} </div>
          <div class="col-sm-2 text-left"></div>
          <div class="col-sm-3"> </div>
        </div>
        <div class="row">
          <div class="col-sm-12"><p>&nbsp;</p></div>
        </div>
        <div class="row">
          <div *ngIf="getWindowInnerWidth() > 780px" class="col-sm-3 text-right">Suitable Audience: </div>
          <div *ngIf="getWindowInnerWidth() < 780px" class="col-sm-3"><p style="font-style: italic;font-weight: bold;">Suitable Audience:</p> </div>
          <div class="col-sm-4">{{product.suitableAudience}}</div>
          <div class="col-sm-2 text-left"></div>
          <div class="col-sm-3"> </div>
        </div>
        <div class="row">
          <div *ngIf="getWindowInnerWidth() > 780px"  class="col-sm-3 text-right">Features: </div>
          <div *ngIf="getWindowInnerWidth() < 780px"  class="col-sm-3"><p style="font-style: italic;font-weight: bold;">Features: </p></div>
          <div class="col-sm-4">{{product.features}}</div>
          <div class="col-sm-2 text-left"></div>
          <div class="col-sm-3"> </div>
        </div>
        <div class="row">
          <div class="col-sm-12"><p>&nbsp;</p></div>
        </div>
        <div class="row">
          <div *ngIf="getWindowInnerWidth() > 780px"  class="col-sm-3 text-right"> Description: </div>
          <div *ngIf="getWindowInnerWidth() < 780px"  class="col-sm-3"><p style="font-style: italic;font-weight: bold;"> Description: </p></div>
          <div class="col-sm-9">
            <h3>{{product.description}} </h3>
          </div>
        </div>
        <div class="row">
          <div *ngIf="getWindowInnerWidth() > 780px" class="col-sm-3 text-right"> Seller Link: </div>
          <div *ngIf="getWindowInnerWidth() < 780px" class="col-sm-3"><p style="font-style: italic;font-weight: bold;"> Seller Link: </p></div>
          <div class="col-sm-9">
            <p><a href="{{product.sellerLink}}" target="_blank" >{{product.sellerLink}}</a></p>
          </div>
        </div>
        <div *ngIf="getWindowInnerWidth() > 780px" class="row">
          <div class="col-sm-4 col-md-3 text-right">
          </div>
          <div class="col-sm-8 col-md-9">
            <a class="btn btn-primary" (click)="goBackToStorePage()">Go Back</a>
          </div>
        </div>
        <div *ngIf="getWindowInnerWidth() < 780px" class="row">
          <div class="col-sm-4 text-right">
          </div>
          <div class="col-sm-8">
            <a class="btn btn-primary" (click)="goBackToStorePage()">Go Back</a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12"><p>&nbsp;</p></div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p style="font-style: italic;">* Please note that the information on this page may not be accurate or uptodate. It is important to review the terms and conditions provided by the sellers through the provided links. For more details, please refer to our
             <a href="#" (click)="openTermsDialog($event)">privacy policy.</a>
            </p>
          </div>

        </div>




    </div>
    </div>
  </div>

  <div class="row">
    <div class="container-fluid">
       <app-footer></app-footer>
    </div>
  </div>

</div>


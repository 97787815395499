import { Injectable } from '@angular/core';
import { RestDataSource } from './rest.datasource';
import { UserRepository } from './user.repository';
import { Reply } from './reply.model';
import { Message } from './message.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class OpenAiRepository {


  constructor(
    private dataSource: RestDataSource, private userRepository: UserRepository
  ) {}

  askQuestion (comment: Message): Observable<Message> {

      return this.dataSource.askQuestion(comment, this.userRepository.getUser().username).pipe(
        map((value: Message) => {
          return value;
        })
      );

  }

  askSummary (articleId: number): Observable<Message> {

    return this.dataSource.askSummary(articleId, this.userRepository.getUser().username).pipe(
      map((value: Message) => {
        return value;
      })
    );

}


askCodingHint (codeContent: Message, questionId: number): Observable<Message> {

  return this.dataSource.askCodingHint(codeContent, questionId, this.userRepository.getUser().username).pipe(
    map((value: Message) => {
      return value;
    })
  );

}


}

<div class="container-fluid">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <p>AI Assistant Chat Service... </p>
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="row">
      <div class="col-12">
        <div *ngIf="isUserEmailExist()">
          <div class="chat-header-bar bg-blue text-white p-2  remove-margin-padding round-top-borders">
              <h2 style="display: inline;" class="text-center m-0"> Assistant <i class="fa fa-robot"></i></h2> <p style="display: inline;">&nbsp; &nbsp;</p> <p style="display: inline;"> <mat-spinner style="display: inline;" *ngIf="showSpinner" diameter="20"></mat-spinner></p>
          </div>
          <div id="main__chat_window">
            <ul *ngFor="let message of getMessages()" class="messages">
              <li *ngIf="message.isQuestionType">{{message.sender +":"+ message.question}}</li>
              <li *ngIf="!message.isQuestionType">{{ message.reply}}</li>
            </ul>
          </div>
          <br />
          <textarea id="chat_message" #chat_message name="chat_message" type="text" autocomplete="off"
            placeholder="Type your message here..." minlength="1" maxlength="250" rows="4"
            data-toggle="tooltip" title="Please enter between 1 and 250 characters."></textarea>
          <div class="bg-danger mt-2 p-2 text-center text-white" *ngIf="errorMessage != null">
            Error: {{errorMessage}}
          </div>

          <div>
            <button class="btn btn-primary m-1" (click)="sendMessage(chat_message.value)">Send</button>
          </div>
        </div>
        <div *ngIf="!isUserEmailExist()">
          <p> Please login to your account to use this AI Assistant chat service </p>
        </div>
        <br />
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p><i class="fa fa-info-circle"></i> Tips for asking Questions...</p>
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <ul>
              <li>Ask questions about this Web Application and features</li>
              <li>Ask how to use provided features</li>
              <li>Discuss Computer Science related topics</li>
              <li>Use "Ask AI for Summary" button next to articles to get summary of articles</li>
            </ul>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </mat-expansion-panel>

</div>

import { Component, AfterViewInit, ViewChildren, OnInit, QueryList } from '@angular/core';
import {   QuestionType } from "../../service/constants";
import { WeeklyquizComponent } from './weekly-quiz/weeklyquiz.component';
import { Behaviour } from 'src/app/model/Behaviour.model';
import { UserRepository } from "src/app/model/user.repository";
import { Router } from '@angular/router';

@Component({
  selector: 'app-weekly-quiz-wrapper',
  templateUrl: './weekly-quiz-wrapper.component.html',
  styleUrls: ['./weekly-quiz-wrapper.component.css']
})
export class WeeklyQuizWrapperComponent implements AfterViewInit, OnInit {

  // @ViewChildren(WeeklyquizComponent) weeklyQuizzes: [];
  @ViewChildren(WeeklyquizComponent) weeklyQuizzes!: QueryList<WeeklyquizComponent>;
  public localQuestionType: any;
  private quizIndex: number = 0;
  public isCompleted: boolean = false;

  constructor(private userRepository: UserRepository, private router: Router) { }

  ngOnInit(): void {
    this.localQuestionType = QuestionType;
  }

  ngAfterViewInit(): void {
    console.log("Total WeeklyQuiz instances:", this.weeklyQuizzes.length);
  }

  next() {

    let behaviour: Behaviour = new Behaviour();
    behaviour.description = "User is clicking next button from Weekly Quiz Wrapper page";
    this.userRepository.trackUserBehaviour(behaviour);

    const firstQuizInstance = this.weeklyQuizzes.first;
    this.quizIndex++;
    this.weeklyQuizzes.forEach( (quizInstance: WeeklyquizComponent)=> {
      quizInstance.nextQuiz(this.quizIndex);
    })

    if (this.quizIndex >= firstQuizInstance.getNumExistingSubject()) {
      this.isCompleted = true;
    }

  }

  restart() {

    let behaviour: Behaviour = new Behaviour();
    behaviour.description = "User is clicking restart button from Weekly Quiz Wrapper page";
    this.userRepository.trackUserBehaviour(behaviour);

    this.quizIndex = 0;
    this.isCompleted = false;
      this.weeklyQuizzes.forEach( (quizInstance: WeeklyquizComponent)=> {
        quizInstance.nextQuiz(this.quizIndex);
      })
  }

  navigateToSignUp(position: string) {

    let behaviour: Behaviour = new Behaviour();
    behaviour.description = "User is clicking Sign up button from "+position+" landing/store comp page";
    this.userRepository.trackUserBehaviour(behaviour);

    this.router.navigate(['registration']);
 }


 navigateToMyAccount(position: string) {

  let behaviour: Behaviour = new Behaviour();
  behaviour.description = "User is clicking my-account button from "+position+" landing/store comp page";
  this.userRepository.trackUserBehaviour(behaviour);

  this.router.navigate(['myaccount']);
}


}

<div class="container-fluid">

  <div class="row bg-dark">
    <div class="container">
      <app-nav-bar></app-nav-bar>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">
      <div class="bg-danger mt-2 p-2 text-center text-white" *ngIf="errorMessage != null">
        Error: {{errorMessage}}
      </div>
      <div id="main-container" class="bg-success mt-2 p-2 text-center text-white" *ngIf="successMessage != null">
        {{successMessage}}
      </div>
    </div>
    <div class="col-sm-2"></div>
  </div>






  <div class="row" *ngIf="successMessage == null">
    <div class="col-sm-2"></div>

    <div class="col-sm-8">
      <div class="container-sm my-3 p-2 bg-success text-white border rounded">
        <form novalidate #form="ngForm" (ngSubmit)="authenticate(form)"  >

          <div class="form-row">
            <div class="form-group col-sm-6">
              <legend class="legendMoveRightMini">Email</legend>
              <input email class="form-control inputEmail" id="email" name="email" [(ngModel)]="feedback.email" required
                #email="ngModel" />
              <div *ngIf="(!email.errors?.emailTaken) && email.invalid && (email.dirty || email.touched)" class="alert">
                <div> Please enter valid email. </div>
              </div>
              <div *ngIf="email.errors?.emailTaken && (email.dirty || email.touched)" class="alert">Email is already
                taken</div>
            </div>
          </div>
          <br />

          <div class="form-group col-sm-12">
            <div class="fieldset-wrapper">
              <fieldset>
                <legend>Would you prefer to see more courses and articles taught by lecturers from:</legend>
                <p class="field">
                  <label><input name="comment-remember" type="radio"
                      value="Uni"  [(ngModel)]="feedback.preferLect"  />Universities/Institutes</label>
                  &nbsp; | &nbsp;
                  <label><input name="preferLect" type="radio" value="Industry" [(ngModel)]="feedback.preferLect" />Industry</label>
                  &nbsp; | &nbsp;
                  <label><input name="preferLect" type="radio" value="UniAndInd" checked="checked"  [(ngModel)]="feedback.preferLect"  />Both</label>
                </p>
              </fieldset>
            </div>

          </div>

          <div class="form-group col-sm-12">
            <div class="fieldset-wrapper">
            <fieldset>
              <legend> Would you like to see time-constrained practical tests including coding tests?</legend>
              <p class="field">
                <label><input name="timeConTest" type="radio" value="yes" checked="checked" [(ngModel)]="feedback.timeConTest"  />Yes</label>
                &nbsp; | &nbsp;
                <label><input name="timeConTest" type="radio" value="No"  [(ngModel)]="feedback.timeConTest"  />No</label>
              </p>
            </fieldset>
            </div>
          </div>

          <div class="form-group col-sm-12">
            <div class="fieldset-wrapper">
            <fieldset>
              <legend>How much would you be willing to pay for a monthly subscription to use our web application?
              </legend>
              <p class="field">
                <label><input name="preferSubAmo" type="radio" value="<5" [(ngModel)]="feedback.preferSubAmo" />Less than NZD $5</label>
                &nbsp; | &nbsp;
                <label><input name="preferSubAmo" type="radio" value=">=5And<=10" checked="checked" [(ngModel)]="feedback.preferSubAmo"  />Between NZD $5 and $10</label>
                &nbsp; | &nbsp;
                <label><input name="preferSubAmo" type="radio" value=">10" [(ngModel)]="feedback.preferSubAmo" />More than NZD $10</label>
              </p>
            </fieldset>
            </div>
          </div>
          <br />
          <div class="form-row">
            <div class="form-group col-sm-11">
              <legend class="legendMoveRightMini">What other features, courses and services we could provide to enhance your learning journey?</legend>
              <textarea class="form-control" name="comment" id="comment" [(ngModel)]="feedback.comment" maxlength="400"
                #comment="ngModel"></textarea>
              <div *ngIf=" comment.invalid && (comment.dirty || comment.touched)" class="alert">
                <div>Please enter up to 400 characters only. Thanks. </div>
              </div>
            </div>
          </div>

          <!--TODO: Password Re-enter field-->


          <div class="form-row">
            <div class="form-goup col-sm-8">
              <div class="form-check">

                  <legend class="legendMoveRightMini">Please untick the check box if you don't want to get futher communication
                    from us.
                    <input type="checkbox" id="subscriptionCheck" name="subscriptionCheck"
                    [(ngModel)]="feedback.acceptForCont" />
                  </legend>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button class="btn btn-primary m-1" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>

    <div class="col-sm-2"></div>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-sm-12 col-md-8">
      <p style="font-style: italic;">* Please note that by providing your information and registering with us, you
        agreed to our
        <a href="#" (click)="openTermsDialog($event)">privacy policy and Website/Application terms of use.</a>
      </p>
    </div>
    <div class="col-md-2"></div>
  </div>


  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>

<!-- Footer -->
<div class="container-fluid">
<footer class="customFooter">
  <div class="row">
    <div class="col-md-3 p-2">



    </div>
    <div class="col-md-6">
      <!-- Copyright -->
      <div>© 2025 Copyright:  High Tech District
      </div>
      <!-- Copyright -->

    </div>
    <div class="col-md-3 p-2"></div>
  </div>
  <div class="row">
    <div class="col-md-4 p-1">
      <h2>Memberships</h2>
      <a href="https://edtechnz.org.nz/our-members/" target="_blank"><img class="square-image" src="../../assets/EdTechNZ.png" /></a>
    </div>
    <div class="col-md-4 p-1">
      <h2>Mentorships</h2>
      <a href="https://startupaotearoa.org/" target="_blank"><img class="round-image" src="../../assets/startup_aotearoa.JPG" /></a>
      &nbsp; &nbsp;
      <a href="https://ministryofawesome.com/" target="_blank"><img class="round-image" src="../../assets/moa.png" /></a>

    </div>

    <div class="col-md-3 p-1">
      <h2>About Us <a style="cursor: pointer;" (click)="goToCompanyLinkedIn()"><img class ="social_icon" src="../../assets/linkedin.JPG"></a></h2>
      <a style="cursor: pointer;" (click)="navigateToAboutUs()">About Us</a> <br/>
      <a style="cursor: pointer;" (click)="navigateToCredit()">Credits</a> <br/>
      <a href="#" (click)="opensTermsDialog($event)">Privacy Policy</a> <br/>
      <a href="#" (click)="opensTermsDialog($event)">Terms and Conditions</a> <br/>
      <a style="cursor: pointer;" (click)="navigateToLecturerLogin()">Lecturer Login</a>
    </div>

  </div>

  <div class="row">
    <div class="col-md-4 p-1">
    </div>
    <div class="col-md-4 p-1">
      <div class="helping-hand">
        <p>❤️ <strong>Support Myanmar Earthquake Relief</strong> ❤️ Thousands are in urgent need of aid.</p>
        <a (click)="goToUnicef()" class="donate-button">Donate to UNICEF</a>
    </div>

    </div>

    <div class="col-md-3 p-1">
    </div>

  </div>


</footer>
</div>
<!-- Footer -->

<h1>Terms and Conditions</h1>
<p>High Tech District (registered as HIGHTECHDISTRICT.COM LIMITED) operates this website. All references to 'we,' 'us,' and 'our' refer to High Tech District.</p>
<p>We are not liable for any direct, indirect, incidental, or consequential losses arising from your use of our website or web application. By using our platform, you acknowledge and accept that your use is at your own risk.</p>

<div *ngIf="isAcceptDeclineButton">
  <button class="btn btn-primary m-1" (click)="accept()">Accept</button>

  <button class="btn btn-danger m-1" (click)="decline()">Decline</button>
</div>
<div *ngIf="!isAcceptDeclineButton">
  <button class="btn btn-primary m-1" (click)="accept()">Close</button>
</div>

import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { FeedbackRepository } from "../model/feedback.repository";
import { Feedback} from "../model/feedback.model";
import { TermsDialogComponent } from '../service/terms-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'lodash';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

 public errorMessage: string;
 public successMessage: string;
 feedback: Feedback = new Feedback();

  constructor(private dialog: MatDialog, private feedbackRepository: FeedbackRepository) {

  }

  ngOnInit(): void {
    this.feedback.acceptForCont=true;
    this.feedback.preferSubAmo=">=5And<=10";
    this.feedback.timeConTest="yes";
    this.feedback.preferLect="UniAndInd";

  }

  authenticate(form: NgForm) {
    if (form.valid) {

      this.feedbackRepository.createFeedback(this.feedback).subscribe(data => {
        this.successMessage = data.message;
        this.errorMessage = null;
        this.resetToDefaultFormValues();
      });

    } else {
      this.errorMessage = "Form Data Invalid";
      this.successMessage = null;
    }
  }


  openTermsDialog(event: Event): void {
    event.preventDefault();

    const dialogConfig = {
      data: {
        isAcceptDecline: false,
      },
    };

    const dialogRef = this.dialog.open(TermsDialogComponent, dialogConfig);
  }

  resetToDefaultFormValues() {
    this.feedback.acceptForCont=true;
    this.feedback.preferSubAmo=">=5And<=10";
    this.feedback.timeConTest="yes";
    this.feedback.preferLect="UniAndInd";
    this.feedback.comment="";
    this.feedback.email="";
  }

}

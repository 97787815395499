<app-nav-bar></app-nav-bar>

<div class="container-fluid">
  <!--
  <div class="row" id="main-container" [style.height]="getOptimalMainContainerHeight()">
    <div *ngIf="getWindowInnerWidth() > 780px" class="col-md-2">

    </div>-->
  <!-- This is for wider screen like laptop phone-->
  <div class="row bg-white" id="main-container">
    <div class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">
      <div class="row main-article">
        <div class="row fullWidth">
          <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting','Courier New', Courier, monospace;">Foundation Level Articles</h1>
        </div>
        <div class="row">
          <div *ngIf="getArticles() == null || getArticles().length == 0">
            <h3>Sorry, couldn't find articles you are looking for. You can search by following Article type:</h3>
            <ul>
              <li> Java</li>
              <li>JavaScript</li>
              <li>ML</li>
              <li>HTML_CSS</li>
            </ul>
            <h3>Alternatively, you can also search by Article title (without quotes) such as "Introduction To Java"</h3>
          </div>
        </div>
        <div class="row">
          <ng-template ngFor let-article [ngForOf]="getArticles()">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="container-sm mt-3  remove-margin-padding round-top-borders" >
                <!-- Header section -->
                <div class="header bg-purple text-white p-2 remove-margin-padding  round-top-borders"
                [class.bg-purple]="article?.lectureType===LocalLectureType.Article" [class.bg-blue]="article?.lectureType===LocalLectureType.Video" >
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                </div>
                <div class="card bg-light remove-margin-padding">
                  <!--  <img class="card-img-top" src="{{article.images[0].location}}{{article.images[0].name}}" alt="Card image cap">-->
                  <img *ngIf="article?.images[0]?.location" class="card-img-top" src="{{article?.images[0]?.location}}" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Article)"  class="card-img-top" src="../../assets/AI.JPG" alt="article image">
                  <img *ngIf="(!article?.images[0]?.location) && (article?.lectureType===LocalLectureType.Video)"  class="card-img-top" src="../../assets/VideoLecture_Icon.jpg" alt="article image">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-10">
                        <h2 class="card-title">{{article?.title}}</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <h3 class="card-title">Author: {{article?.user?.username}}</h3>
                      </div>
                    </div>
                    <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                    <div class="row">
                      <div class="col-md-6">
                        <a (click)="navigateToArticleDetail(article?.id)" class="btn btn-primary">More Details</a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </ng-template>
        </div>
      </div>

      <!--
      <div class="form-inline float-left mr-1" *ngIf="getProductSize() > 0">
        <select class="from-control" [value]="articlesPerPage" (change)="changePageSize($event.target.value)">
          <option value="3">3 per Page</option>
          <option value="4">4 per Page</option>
          <option value="6">6 per Page</option>
        </select>
      </div> -->

      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCount" (click)="changePage(page)" class="btn btn-outline-primary"
          [class.active]="page == selectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>


    </div>
    <div class="col-sm-2 col-md-3">
      <!-- <div class="card m-1 p-1 bg-light">
        <img class="card-img-top" src="../../assets/crypto_com.JPG" alt="Card image cap">
        <div class="card-body">
          <div class="row">
            <div class="col-md-10">
              <h5 class="card-title">Interested in staking crypto coin?</h5>
            </div>

          </div>
          <p class="card-text">Join crypto.com exchange and stake cro. Use referal code esqk2y962e to get bonus.
            Please invest responsibly</p>
          <div class="row">
            <div class="col-md-6"><a href="https://crypto.com/exch/esqk2y962e" target="_blank"
                class="btn btn-primary">More Detais</a> </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="row bg-grey" id="main-container">
    <div class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">

      <div class="row " id="main-container">
        <!-- Since title is only taking small part of the row, whenever second row has only one article that can
        fit the remaining available space of first row then content from second row is jumping up a bit. fullWidth is
        helping to mitigate the problem-->
        <div class="row fullWidth">
          <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting', 'Courier New', Courier, monospace;">Intermediate Level Articles      </h1>
        </div>
        <div class="row">
          <div *ngIf="getIntermediateLevelArticles() == null || getIntermediateLevelArticles().length == 0">
            <p>We don't have intermediate level articles at this moment. Please come back later.</p>
          </div>
          <ng-template ngFor let-article [ngForOf]="getIntermediateLevelArticles()">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="container-sm mt-3  remove-margin-padding round-top-borders" >
                <!-- Header section -->
                <div class="header bg-purple text-white p-2 remove-margin-padding  round-top-borders"
                [class.bg-purple]="article?.lectureType===LocalLectureType.Article" [class.bg-blue]="article?.lectureType===LocalLectureType.Video" >
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Article" class="text-center m-0"><i class="fa fa-book"></i></h2>
                  <h2 *ngIf="article?.lectureType===LocalLectureType.Video" class="text-center m-0"><i class="fa fa-play"></i></h2>
                </div>
                <div class="card bg-light remove-margin-padding">
                  <!--  <img class="card-img-top" src="{{article.images[0].location}}{{article.images[0].name}}" alt="Card image cap">-->
                  <img class="card-img-top" src="{{article?.images[0]?.location}}" alt="Card image cap" />
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-10">
                        <h5 class="card-title">{{article?.title}}</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <h5 class="card-title">Author:{{article?.user?.username}}</h5>
                      </div>
                    </div>
                    <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                    <div class="row">
                      <div class="col-md-6">
                        <a (click)="navigateToArticleDetail(article?.id)" class="btn btn-primary">More Details</a>
                      </div>
                    </div>
                  </div>

                </div>
             </div>


            </div>
          </ng-template>
        </div>
      </div>

      <div class="btn-group float-right">
        <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCount" (click)="changePage(page)" class="btn btn-outline-primary"
          [class.active]="page == intermediateSelectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="col-sm-2 col-md-3">

    </div>
  </div>

  <div class="row bg-white" id="main-container">
    <div class="col-md-1">
      <!--This is just to push main content to the middle in wider screen-->
    </div>
    <div class="col-sm-10 col-md-8">

      <div class="row " id="main-container">
        <!-- Since title is only taking small part of the row, whenever second row has only one article that can
        fit the remaining available space of first row then content from second row is jumping up a bit. fullWidth is
        helping to mitigate the problem-->
        <div class="row fullWidth">
          <h1 style="text-decoration: underline; font-family: 'Lucida Handwriting','Courier New', Courier, monospace;">Advanced Level Articles      </h1>
        </div>
        <div class="row">
          <div *ngIf="getAdvancedLevelArticles() == null || getAdvancedLevelArticles().length == 0">
            <p>We don't have advanced level articles at this moment. Please come back later.</p>
          </div>
          <ng-template ngFor let-article [ngForOf]="getAdvancedLevelArticles()">
            <div class="col-sm-12 col-md-4 p-2">
              <div class="card m-1 p-1 bg-light">
                <!--  <img class="card-img-top" src="{{article.images[0].location}}{{article.images[0].name}}" alt="Card image cap">-->
                <img class="card-img-top" src="{{article?.images[0]?.location}}" alt="Card image cap" />
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-10">
                      <h5 class="card-title">{{article?.title}}</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-10">
                      <h5 class="card-title">Author:{{article?.user?.username}}</h5>
                    </div>
                  </div>
                  <p class="card-text">{{article?.introduction?.slice(0,65)}}...</p>
                  <div class="row">
                    <div class="col-md-6">
                      <a (click)="navigateToArticleDetail(article?.id)" class="btn btn-primary">More Details</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>

        </div>
      </div>

      <div class="btn-group float-right">
       <!-- <button *ngFor="let page of pageNumbers" (click)="changePage(page)" class="btn btn-outline-primary" [class.active]="page == selectedPage">-->
        <button *counter="let page of pageCount" (click)="changePage(page)" class="btn btn-outline-primary"
          [class.active]="page == advancedSelectedPage">
          {{page}}
        </button>
      </div>
      <div style="clear: both;"></div>
    </div>
    <div class="col-sm-2 col-md-3">

    </div>
  </div>



  <div class="row footerSection">
    <div class="container-fluid">
      <app-footer></app-footer>
    </div>
  </div>

</div>
